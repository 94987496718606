<template>
  <h1>Artikel bearbeiten</h1>
  <LoadingSpinner
    v-if="loading || deleteLoading"
    text="... die Daten werden bearbeitet ..."
  />

  <div
    v-else-if="!loading && artikeltemplate == null"
    class="alert alert-warning"
  >
    <router-link
      :to="{ name: 'Artikeltemplates' }"
      class="btn btn-outline-secondary my-auto me-4"
      >Zurück</router-link
    >
    <strong>Ungültiger Artikel</strong>
  </div>

  <div v-else>
    <form @submit.prevent="updateCurrentArtikeltemplate">
      <h3>Template</h3>
      <div class="mb-3">
        <label for="artikel-titel" class="form-label">Titel</label>
        <input
          type="text"
          v-model="updateArtikeltemplate.template.titel"
          class="form-control"
          id="artikel-titel"
        />
      </div>

      <div class="mb-3">
        <label for="artikel-beschreibung" class="form-label"
          >Beschreibung</label
        >
        <textarea
          class="form-control"
          v-model="updateArtikeltemplate.template.beschreibung"
          id="artikel-beschreibung"
          rows="3"
        ></textarea>
      </div>

      <hr class="my-3" />

      <h3>Artikel Template Definition</h3>

      <div class="row mb-3">
        <div class="col-4">
          <label for="artikelart" class="form-label"
            >Artikelart auswählen</label
          >
          <select
            class="form-select"
            id="artikel-template"
            v-model="updateArtikeltemplate.template.artikelartid"
          >
            <option
              v-for="artikelart in artikelarten"
              :key="artikelart.id"
              :value="artikelart.id"
            >
              {{ artikelart.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mb-3">
        <label for="artikel-titel" class="form-label">ArtikelTitel</label>
        <input
          type="text"
          v-model="updateArtikeltemplate.template.artikeltitel"
          class="form-control"
          id="artikel-titel"
        />
      </div>

      <div class="mb-3">
        <label for="artikel-nr" class="form-label">Artikelnummer</label>
        <input
          type="text"
          v-model="updateArtikeltemplate.template.artikelnummer"
          class="form-control"
          id="artikel-nr"
        />
      </div>

      <div class="mb-3">
        <label for="artikel-beschreibung" class="form-label"
          >Artikel Beschreibung</label
        >
        <textarea
          class="form-control"
          v-model="updateArtikeltemplate.template.artikelbeschreibung"
          id="artikel-beschreibung"
          rows="3"
        ></textarea>
      </div>

      <hr class="my-3" />

      <!-- EIGENSCHAFTEN -->
      <div class="mb-3">
        <p class="fw-bold">Eigenschaften</p>

        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <span>Eigenschaft</span>
            </div>
          </div>

          <div class="col-7">
            <div>
              <label class="form-label">Wert</label>
            </div>
          </div>

          <div class="col-2"></div>
        </div>

        <div
          v-for="(eigenschaft, index) in updateArtikeltemplate.template
            .eigenschaften"
          :key="index"
        >
          <div class="row w-50 mb-3">
            <div class="col-3">
              <div>
                <input
                  type="text"
                  v-model="eigenschaft.key"
                  class="form-control"
                  :id="'key-' + index"
                  placeholder="Farbe"
                  required
                />
              </div>
            </div>

            <div class="col-7">
              <div>
                <input
                  type="text"
                  v-model="eigenschaft.value"
                  class="form-control"
                  :id="'value-' + index"
                  placeholder="Rot"
                  required
                />
              </div>
            </div>
            <div class="col-2">
              <button
                type="button"
                class="btn text-secondary"
                title="Entfernen"
                @click="removeEigenschaft(index)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="btn btn-secondary"
            @click="addEigenschaft()"
          >
            <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
          </button>
        </div>
      </div>

      <hr class="my-3" />

      <!-- Tags -->
      <div class="my-3">
        <p class="fw-bold">Tags</p>

        <div class="d-flex flex-row mb-3 flex-wrap">
          <p
            v-for="tag in updateArtikeltemplate.template.tags"
            :key="tag.tagid"
            class="lead me-2"
          >
            <span class="badge text-bg-primary">{{
              tags.find((t) => t.id == tag.tagid)?.key
            }}</span>
          </p>
        </div>

        <ArtikeltemplateTagSelectModal
          :tags="updateArtikeltemplate.template.tags.map((t) => t.tagid)"
          @set-selected-tags="setSelectedTags"
        />
      </div>

      <hr class="my-3" />

      <!-- EINHEITEN -->
      <div v-if="updateArtikeltemplate.template.artikelartid == 3" class="mb-3">
        <p class="fw-bold">Einheit</p>

        <div
          v-for="(einheit, index) in updateArtikeltemplate.template.einheiten"
          :key="index"
        >
          <div class="row w-75 mb-3">
            <div class="col">
              <select
                class="form-select"
                v-model="einheit.artikelEinheitid"
                @change="
                  setEinheitArtikelEinheit(index, einheit.artikelEinheitid)
                "
              >
                <option
                  v-for="ae in artikeleinheiten"
                  :key="ae.id"
                  :value="ae.id"
                >
                  {{ ae.name }} ({{ ae.kurz }})
                </option>
              </select>
            </div>

            <!-- <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheit(index)"><font-awesome-icon icon="trash" /></button>
            </div>

            <div class="col">{{ einheit }}</div>  -->
          </div>
        </div>
      </div>

      <div v-else class="mb-3">
        <p class="fw-bold">Einheiten</p>

        <div class="alert alert-info">
          Die Reihenfolge der Einheiten ist entscheident. Die Einheiten müssen
          absteigend nach Größe sortiert werden (größte Einheit nach oben).
        </div>

        <div
          v-for="(
            einheit, index
          ) in updateArtikeltemplate.template.einheiten.sort(
            (a, b) => a.pos - b.pos
          )"
          :key="index"
          class="row"
        >
          <div class="col-1 d-flex flex-column justify-content-start">
            <button
              type="button"
              class="btn btn-sm"
              @click="einheitPosUp(index)"
              :disabled="einheit.pos == 1"
            >
              <font-awesome-icon icon="chevron-up" />
            </button>
            <button
              type="button"
              class="btn btn-sm"
              @click="einheitPosDown(index)"
              :disabled="
                einheit.pos == updateArtikeltemplate.template.einheiten.length
              "
            >
              <font-awesome-icon icon="chevron-down" />
            </button>
          </div>

          <div class="col">
            <div class="row w-75 mb-3">
              <div class="col-2">
                <input
                  class="form-control"
                  type="number"
                  :name="'einheit-anzahl-' + index"
                  :id="'einheit-anzahl-' + index"
                  v-model="einheit.anzahl"
                />
              </div>

              <div class="col">
                <select class="form-select" v-model="einheit.artikelEinheitid">
                  <option
                    v-for="ae in artikeleinheiten"
                    :key="ae.id"
                    :value="ae.id"
                  >
                    {{ ae.name }} ({{ ae.kurz }})
                  </option>
                </select>
              </div>

              <div class="col-2">
                <button
                  type="button"
                  class="btn text-secondary float-end"
                  title="Entfernen"
                  @click="removeEinheit(index)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>

            <div class="mb-3 d-flex">
              <div class="form-check me-5">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="'standard-einheit-' + index"
                  :id="'standard-einheit-' + index"
                  :value="einheit.id"
                  v-model="updateArtikeltemplate.defaulteinheit"
                />
                <label
                  class="form-check-label"
                  :for="'standard-einheit-' + index"
                  >Standard</label
                >
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :name="'einzeln-einlagern-' + index"
                  :id="'einzeln-einlagern-' + index"
                  v-model="einheit.einzelnEinlagern"
                />
                <label
                  class="form-check-label"
                  :for="'einzeln-einlagern-' + index"
                  >Einzeln Einlagern</label
                >
              </div>
            </div>

            <div class="mb-3 ms-4">
              <p class="fw-bold">Einheit Eigenschaften</p>

              <div class="row w-50 mb-3">
                <div class="col-4">
                  <div>
                    <span>Eigenschaft</span>
                  </div>
                </div>

                <div class="col-8">
                  <div>
                    <label class="form-label">Wert</label>
                  </div>
                </div>
              </div>

              <div
                v-for="(eigenschaft, idx) in einheit.eigenschaften.filter(
                  (e: ArtikeltemplateEinheitEigenschaft) =>
                    e.artikeltemplateArtikelEinheitid == einheit.id
                )"
                :key="index"
              >
                <div class="row w-50 mb-3">
                  <div class="col-3">
                    <div>
                      <input
                        type="text"
                        v-model="eigenschaft.key"
                        class="form-control"
                        :id="'key-' + idx"
                        placeholder="Farbe"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-7">
                    <div>
                      <input
                        type="text"
                        v-model="eigenschaft.value"
                        class="form-control"
                        :id="'value-' + idx"
                        placeholder="Rot"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn text-secondary"
                      title="Entfernen"
                      @click="removeEinheitEigenschaft(index, eigenschaft)"
                    >
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="addEinheitEigenschaft(index, einheit.id)"
                >
                  <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                </button>
              </div>
            </div>
          </div>

          <hr class="my-4" />
        </div>

        <button type="button" class="btn btn-secondary" @click="addEinheit()">
          <font-awesome-icon icon="plus" /> Einheit hinzufügen
        </button>
      </div>

      <!-- VARIANTENEIGENSCHAFTEN -->
      <div class="mb-3" v-if="updateArtikeltemplate.template.artikelartid == 2">
        <hr class="my-3" />

        <p class="fw-bold">Varianten</p>

        <div class="mb-3">
          <p class="fw-bold mb-3">Varianten Eigenschaften</p>

          <div class="row w-50 mb-3">
            <div class="col-4">
              <div>
                <span>Eigenschaft</span>
              </div>
            </div>

            <div class="col-8">
              <div>
                <label class="form-label">Werte</label>
              </div>
            </div>
          </div>

          <div
            v-for="(
              eigenschaft, index
            ) in updateArtikeltemplate.variantenEigenschaften"
            :key="index"
          >
            <div class="row mb-4">
              <div class="col-2">
                <div>
                  <input
                    type="text"
                    v-model="eigenschaft.key"
                    class="form-control"
                    :id="'key-' + index"
                    placeholder="Farbe"
                    required
                  />
                </div>
              </div>

              <div class="col-8">
                <div class="row">
                  <div class="col-5">Wert</div>
                  <div class="col-5">Artikelnummer Erweiterung</div>
                  <div class="col-2"></div>
                </div>

                <div
                  class="row mb-2"
                  v-for="(wert, windex) in eigenschaft.values"
                  :key="'key-' + index + '-' + windex"
                >
                  <div class="col-5">
                    <input
                      type="text"
                      class="form-control"
                      v-model="wert.value"
                      placeholder="Rot"
                      required
                    />
                  </div>

                  <div class="col-5">
                    <input
                      type="text"
                      class="form-control"
                      v-model="wert.artikelnummerextension"
                      placeholder="04"
                      required
                    />
                  </div>

                  <div class="col-2">
                    <button
                      type="button"
                      class="btn text-secondary"
                      title="Entfernen"
                      @click="removeVarianteEigenschaftValue(index, windex)"
                    >
                      <font-awesome-icon icon="trash" />
                    </button>
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="addVarianteEigenschaftValue(index)"
                  >
                    <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                  </button>
                </div>
              </div>

              <div class="col-2">
                <button
                  type="button"
                  class="btn text-secondary"
                  title="Entfernen"
                  @click="removeVarianteEigenschaft(index)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </div>
            </div>
          </div>

          <div>
            <button
              type="button"
              class="btn btn-secondary"
              @click="addVarianteEigenschaft()"
            >
              <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
            </button>
          </div>
        </div>
      </div>

      <hr class="my-3" />

      <div v-if="errorMsg != ''" class="alert alert-danger">
        Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
      </div>

      <div class="d-flex justify-content-end py-3">
        <button
          type="button"
          @click="cancel()"
          class="btn btn-outline-secondary me-2 float-end"
        >
          Abbrechen
        </button>
        <button type="submit" class="btn btn-success">
          Änderungen speichern
        </button>
      </div>
    </form>

    <hr class="my-5" />

    <p class="fw-bold">Artikeltemplate löschen</p>
    <button
      type="button"
      class="btn btn-danger"
      @click="deleteCurrentArtikeltemplate"
    >
      <font-awesome-icon icon="trash" /> Artikeltemplate löschen
    </button>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, reactive } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { v4 as uuidv4 } from 'uuid';

  import {
    ArtikelEinheit,
    EditVariantenEigenschaft,
    EditVariantenEigenschaftValue,
  } from '@/models/ArtikelModels';
  import {
    Artikeltemplate,
    ArtikeltemplateEigenschaft,
    ArtikeltemplateVariantenEigenschaft,
    ArtikeltemplateArtikelEinheit,
    ArtikeltemplateEinheitEigenschaft,
    ArtikeltemplateTag,
  } from '@/models/ArtikeltemplateModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikelvarianteRow from '@/components/Artikel/ArtikelvarianteRow.vue';
  import ArtikeltemplateTagSelectModal from '@/components/Artikeltemplates/ArtikeltemplateTagSelectModal.vue';

  export default defineComponent({
    name: 'UpdateArtikelView',

    props: {
      id: {
        type: String,
        required: true,
      },
    },

    components: {
      LoadingSpinner,
      ArtikelvarianteRow,
      ArtikeltemplateTagSelectModal,
    },

    setup(props) {
      const store = useStore();
      const router = useRouter();

      const artikeltemplate = computed(() =>
        store.getters.artikeltemplates.find((a) => a.id === parseInt(props.id))
      );
      const artikelarten = computed(() => store.getters.artikelarten);
      const artikeleinheiten = computed(() => store.getters.artikeleinheiten);
      const artikeltemplateinheiten = computed(() => {
        var result = new Array<ArtikeltemplateArtikelEinheit>();
        for (var einheit of artikeleinheiten.value) {
          var value = new ArtikeltemplateArtikelEinheit();
          value.id = einheit.id;
          value.artikelEinheit = einheit;
          value.artikelEinheitid = einheit.id;
          result.push(value);
        }

        return result;
      });

      function getArtikeleinheitInfo(id: number): ArtikelEinheit | undefined {
        return artikeleinheiten.value.find((a: ArtikelEinheit) => a.id == id);
      }

      const tags = computed(() => store.getters.tags);

      const loading = computed(
        () => store.getters.status.updateArtikeltemplateLoading
      );
      const deleteLoading = computed(
        () => store.getters.status.deleteArtikeltemplateLoading
      );
      const errorMsg = computed(
        () => store.getters.status.artikeltemplateErrorMsg
      );

      const updateArtikeltemplate = reactive({
        template: new Artikeltemplate(),
        variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
        defaulteinheit: -1,
      });

      function setEinheitArtikelEinheit(index: number, einheitId: number) {
        const einheit = artikeleinheiten.value.find(
          (ae: ArtikelEinheit) => ae.id == einheitId
        );
        if (einheit != null) {
          updateArtikeltemplate.template.einheiten[index].artikelEinheit =
            einheit;
        }
      }

      if (artikeltemplate.value != null) {
        updateArtikeltemplate.template = JSON.parse(
          JSON.stringify(artikeltemplate.value)
        );
      }

      updateArtikeltemplate.defaulteinheit =
        updateArtikeltemplate.template.einheiten.find(
          (e: ArtikeltemplateArtikelEinheit) => e.default == 1
        )?.id || -1;

      setVariantenEigenschaften();

      function setVariantenEigenschaften() {
        const uniquekeys: Array<string> = [
          ...new Set<string>(
            updateArtikeltemplate.template.variantenEigenschaften.map(
              (i: ArtikeltemplateVariantenEigenschaft) => i.key
            )
          ),
        ];

        for (var key of uniquekeys) {
          const eve = new EditVariantenEigenschaft(key);
          const eigenschaften =
            updateArtikeltemplate.template.variantenEigenschaften.filter(
              (e: ArtikeltemplateVariantenEigenschaft) => e.key == key
            );
          for (var eigenschaft of eigenschaften) {
            eve.values.push(
              new EditVariantenEigenschaftValue(
                eigenschaft.identifier,
                eigenschaft.value,
                eigenschaft.artikelnummerextension,
                eigenschaft.id
              )
            );
          }

          updateArtikeltemplate.variantenEigenschaften.push(eve);
        }
      }

      function setSelectedTags(data: Array<number>) {
        const newTags = new Array<ArtikeltemplateTag>();
        for (var value of data) {
          const index = updateArtikeltemplate.template.tags.findIndex(
            (t) => t.tagid == value
          );
          if (index == -1) {
            const newValue = new ArtikeltemplateTag();
            newValue.artikeltemplateid = updateArtikeltemplate.template.id;
            newValue.tagid = value;
            newTags.push(newValue);
          } else {
            newTags.push(updateArtikeltemplate.template.tags[index]);
          }
        }

        updateArtikeltemplate.template.tags = newTags;
      }

      function addEigenschaft() {
        updateArtikeltemplate.template.eigenschaften.push(
          new ArtikeltemplateEigenschaft()
        );
      }

      function removeEigenschaft(index: number) {
        updateArtikeltemplate.template.eigenschaften.splice(index, 1);
      }

      function addEinheit() {
        const einheit = new ArtikeltemplateArtikelEinheit();
        einheit.pos = updateArtikeltemplate.template.einheiten.length + 1;
        einheit.id = -1 * Math.floor(Math.random() * 91) + 10;
        einheit.artikeltemplateid = updateArtikeltemplate.template.id;
        updateArtikeltemplate.template.einheiten.push(einheit);
      }

      function removeEinheit(index: number) {
        updateArtikeltemplate.template.einheiten.splice(index, 1);
      }

      function einheitPosUp(index: number) {
        updateArtikeltemplate.template.einheiten[index - 1].pos++;
        updateArtikeltemplate.template.einheiten[index].pos--;
      }

      function einheitPosDown(index: number) {
        updateArtikeltemplate.template.einheiten[index + 1].pos--;
        updateArtikeltemplate.template.einheiten[index].pos++;
      }

      function addEinheitEigenschaft(index: number, einheitid: number) {
        const eigenschaft = new ArtikeltemplateEinheitEigenschaft();
        eigenschaft.artikeltemplateArtikelEinheitid = einheitid;
        updateArtikeltemplate.template.einheiten[index].eigenschaften.push(
          eigenschaft
        );
      }

      function removeEinheitEigenschaft(
        index: number,
        eigenschaft: ArtikeltemplateEinheitEigenschaft
      ) {
        var idx = updateArtikeltemplate.template.einheiten[
          index
        ].eigenschaften.findIndex(
          (e: ArtikeltemplateEinheitEigenschaft) =>
            e.key == eigenschaft.key &&
            e.value == eigenschaft.value &&
            e.artikeltemplateArtikelEinheitid ==
              eigenschaft.artikeltemplateArtikelEinheitid
        );
        updateArtikeltemplate.template.einheiten[index].eigenschaften.splice(
          idx,
          1
        );
      }

      function addVarianteEigenschaft() {
        updateArtikeltemplate.variantenEigenschaften.push(
          new EditVariantenEigenschaft('')
        );
      }

      function removeVarianteEigenschaft(index: number) {
        updateArtikeltemplate.variantenEigenschaften.splice(index, 1);
      }

      function addVarianteEigenschaftValue(varianteindex: number) {
        updateArtikeltemplate.variantenEigenschaften[varianteindex].values.push(
          new EditVariantenEigenschaftValue(uuidv4(), '', '')
        );
      }

      function removeVarianteEigenschaftValue(
        varianteindex: number,
        index: number
      ) {
        updateArtikeltemplate.variantenEigenschaften[
          varianteindex
        ].values.splice(index, 1);
      }

      return {
        store,
        router,
        loading,
        deleteLoading,
        errorMsg,
        artikeltemplate,
        artikelarten,
        artikeleinheiten,
        artikeltemplateinheiten,
        getArtikeleinheitInfo,
        tags,
        updateArtikeltemplate,
        setEinheitArtikelEinheit,
        setSelectedTags,
        addEigenschaft,
        removeEigenschaft,
        addEinheit,
        removeEinheit,
        einheitPosUp,
        einheitPosDown,
        addEinheitEigenschaft,
        removeEinheitEigenschaft,
        addVarianteEigenschaft,
        removeVarianteEigenschaft,
        addVarianteEigenschaftValue,
        removeVarianteEigenschaftValue,
      };
    },

    methods: {
      clearData() {
        if (this.artikeltemplate != null) {
          this.updateArtikeltemplate.template = JSON.parse(
            JSON.stringify(this.artikeltemplate)
          );
        }
      },

      cancel() {
        this.clearData();
        this.router.push({
          name: 'ArtikeltemplateDetails',
          params: { id: this.id },
        });
      },

      updateCurrentArtikeltemplate() {
        if (this.artikeltemplate != null) {
          // update varianteneigenschaften
          if (this.updateArtikeltemplate.variantenEigenschaften.length > 0) {
            const varianteneigenschaften =
              new Array<ArtikeltemplateVariantenEigenschaft>();
            for (var eigenschaft of this.updateArtikeltemplate
              .variantenEigenschaften) {
              for (var value of eigenschaft.values) {
                varianteneigenschaften.push(
                  new ArtikeltemplateVariantenEigenschaft(
                    value.id,
                    this.updateArtikeltemplate.template.id,
                    value.identifier,
                    eigenschaft.key,
                    value.value,
                    value.artikelnummerextension
                  )
                );
              }
            }
            this.updateArtikeltemplate.template.variantenEigenschaften =
              varianteneigenschaften;
          }

          // set default einheit
          for (var einheit of this.updateArtikeltemplate.template.einheiten) {
            if (einheit.id < 0) {
              einheit.id = 0;
            }
            if (einheit.id == this.updateArtikeltemplate.defaulteinheit) {
              einheit.default = 1;
            } else {
              einheit.default = 0;
            }
          }

          this.store
            .dispatch(
              ActionTypes.UpdateArtikeltemplate,
              this.updateArtikeltemplate.template
            )
            .then(() => {
              if (this.errorMsg == '') {
                this.cancel();
              }
            });
        }
      },

      deleteCurrentArtikeltemplate() {
        if (this.artikeltemplate != null) {
          this.store
            .dispatch(
              ActionTypes.DeleteArtikeltemplate,
              this.artikeltemplate.id
            )
            .then(() => {
              if (this.errorMsg == '') {
                this.clearData();
                this.router.push({ name: 'Artikeltemplates' });
              }
            });
        }
      },
    },

    mounted() {
      if (this.artikelarten.length == 0) {
        this.store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
      }

      if (this.artikeleinheiten.length == 0) {
        this.store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
      }
    },
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }
</style>
