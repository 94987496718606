<template>
  <h1>Artikel erstellen</h1>
  <LoadingSpinner v-if="loading" text="... der Artikel wird angelegt ..." />

  <form v-else @submit.prevent="createArtikel">
    <!-- SELECT TEMPLATE -->
    <div class="row mb-3">
      <div class="col-4">
        <label for="artikel-template" class="form-label"
          >Artikel Template auswählen</label
        >
        <select
          class="form-select"
          id="artikel-template"
          v-model="selectedArtikeltemplate"
          @change="setArtikeltemplate()"
        >
          <option :value="null">Kein Template...</option>
          <option
            v-for="template in artikeltemplates"
            :key="template.id"
            :value="template"
          >
            {{ template.titel }}
          </option>
        </select>
      </div>
    </div>

    <hr class="my-3" />

    <!-- SELECT ARTIKELART -->
    <div class="row mb-3">
      <div class="col-4">
        <label for="artikelart" class="form-label">Artikelart auswählen</label>
        <select
          class="form-select"
          id="artikel-template"
          v-model="newArtikel.artikel.artikelartid"
          @change="setArtikelart()"
        >
          <option
            v-for="artikelart in artikelarten"
            :key="artikelart.id"
            :value="artikelart.id"
          >
            {{ artikelart.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Titel</label>
      <input
        type="text"
        v-model="newArtikel.artikel.titel"
        class="form-control"
        id="artikel-titel"
        @input="newArtikelEditArtikeltitel()"
        required
      />
    </div>

    <div class="mb-3">
      <label for="artikel-nr" class="form-label">Artikelnummer</label>
      <input
        type="text"
        v-model="newArtikel.artikel.artikelNr"
        class="form-control"
        id="artikel-nr"
        @input="newArtikelEditArtikelnummer()"
      />
    </div>

    <div class="mb-3">
      <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
      <textarea
        class="form-control"
        v-model="newArtikel.artikel.beschreibung"
        id="artikel-beschreibung"
        rows="3"
      ></textarea>
    </div>

    <hr class="my-3" />

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <p class="fw-bold">Eigenschaften</p>

      <div class="row w-50 mb-3">
        <div class="col-4">
          <div>
            <span>Eigenschaft</span>
          </div>
        </div>

        <div class="col-8">
          <div>
            <label class="form-label">Wert</label>
          </div>
        </div>
      </div>

      <div
        v-for="(eigenschaft, index) in newArtikel.artikel.eigenschaften"
        :key="index"
      >
        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <input
                type="text"
                v-model="eigenschaft.key"
                class="form-control"
                :id="'key-' + index"
                placeholder="Farbe"
                required
              />
            </div>
          </div>

          <div class="col-7">
            <div>
              <input
                type="text"
                v-model="eigenschaft.value"
                class="form-control"
                :id="'value-' + index"
                placeholder="Rot"
                required
              />
            </div>
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn text-secondary"
              title="Entfernen"
              @click="removeEigenschaft(index)"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="addEigenschaft()"
        >
          <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
        </button>
      </div>
    </div>

    <hr class="my-3" />

    <!-- TAGS -->
    <div class="my-3">
      <p class="fw-bold">Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p
          v-for="tag in newArtikel.artikel.tags"
          :key="tag.id"
          class="lead me-2"
        >
          <span class="badge text-bg-primary">{{
            tags.find((t) => t.id == tag.tagid)?.key
          }}</span>
        </p>
      </div>

      <ArtikelTagSelectModal
        :tags="newArtikel.artikel.tags.map((t) => t.tagid)"
        @set-selected-tags="setSelectedTags"
      />
    </div>

    <hr class="my-3" />

    <!-- EINHEITEN -->
    <div v-if="newArtikel.artikel.artikelartid == 3" class="mb-3">
      <p class="fw-bold">Einheit</p>

      <div
        v-for="(einheit, index) in newArtikel.artikel.einheiten"
        :key="index"
      >
        <div class="row w-75 mb-3">
          <div class="col">
            <select
              class="form-select"
              v-model="einheit.artikelEinheitid"
              @change="
                setEinheitArtikelEinheit(index, einheit.artikelEinheitid)
              "
            >
              <option
                v-for="ae in artikeleinheiten"
                :key="ae.id"
                :value="ae.id"
              >
                {{ ae.name }} ({{ ae.kurz }})
              </option>
            </select>
          </div>

          <!-- <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheit(index)"><font-awesome-icon icon="trash" /></button>
          </div>

          <div class="col">{{ einheit }}</div>  -->
        </div>
      </div>
    </div>

    <div v-else class="mb-3">
      <p class="fw-bold">Einheiten</p>

      <div class="alert alert-info">
        Die Reihenfolge der Einheiten ist entscheident. Die Einheiten müssen
        absteigend nach Größe sortiert werden (größte Einheit nach oben).
      </div>

      <div
        v-for="(einheit, index) in newArtikel.artikel.einheiten.sort(
          (a, b) => a.pos - b.pos
        )"
        :key="index"
        class="row"
      >
        <div class="col-1 d-flex flex-column">
          <button
            type="button"
            class="btn btn-sm"
            @click="einheitPosUp(index)"
            :disabled="einheit.pos == 1"
          >
            <font-awesome-icon icon="chevron-up" />
          </button>
          <button
            type="button"
            class="btn btn-sm"
            @click="einheitPosDown(index)"
            :disabled="einheit.pos == newArtikel.artikel.einheiten.length"
          >
            <font-awesome-icon icon="chevron-down" />
          </button>
        </div>

        <div class="col">
          <div class="row w-75 mb-3">
            <div class="col-2">
              <input
                class="form-control"
                type="number"
                :name="'einheit-anzahl-' + index"
                :id="'einheit-anzahl-' + index"
                v-model="einheit.anzahl"
              />
            </div>

            <div class="col">
              <select
                class="form-select"
                v-model="einheit.artikelEinheitid"
                @change="
                  setEinheitArtikelEinheit(index, einheit.artikelEinheitid)
                "
              >
                <option
                  v-for="ae in artikeleinheiten"
                  :key="ae.id"
                  :value="ae.id"
                >
                  {{ ae.name }} ({{ ae.kurz }})
                </option>
              </select>
            </div>

            <div class="col-2">
              <button
                type="button"
                class="btn text-secondary"
                title="Entfernen"
                @click="removeEinheit(index)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>

          <div class="mb-3 d-flex">
            <div class="form-check me-5">
              <input
                class="form-check-input"
                type="radio"
                :name="'standard-einheit-' + index"
                :id="'standard-einheit-' + index"
                :value="einheit.id"
                v-model="newArtikel.defaulteinheit"
              />
              <label class="form-check-label" :for="'standard-einheit-' + index"
                >Standard</label
              >
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :name="'einzeln-einlagern-' + index"
                :id="'einzeln-einlagern-' + index"
                v-model="einheit.einzelnEinlagern"
              />
              <label
                class="form-check-label"
                :for="'einzeln-einlagern-' + index"
                >Einzeln Einlagern</label
              >
            </div>
          </div>

          <div class="mb-3 ms-4">
            <p class="fw-bold">Einheit Eigenschaften</p>

            <div class="row w-50 mb-3">
              <div class="col-4">
                <span>Eigenschaft</span>
              </div>

              <div class="col-8">
                <label class="form-label">Wert</label>
              </div>
            </div>

            <div
              v-for="(eigenschaft, index) in einheit.eigenschaften.filter(
                (e) => e.artikelArtikelEinheitid == einheit.id
              )"
              :key="index"
            >
              <div class="row w-50 mb-3">
                <div class="col-3">
                  <div>
                    <input
                      type="text"
                      v-model="eigenschaft.key"
                      class="form-control"
                      :id="'key-' + index"
                      placeholder="Farbe"
                      required
                    />
                  </div>
                </div>

                <div class="col-7">
                  <div>
                    <input
                      type="text"
                      v-model="eigenschaft.value"
                      class="form-control"
                      :id="'value-' + index"
                      placeholder="Rot"
                      required
                    />
                  </div>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn text-secondary"
                    title="Entfernen"
                    @click="removeEinheitEigenschaft(index, eigenschaft)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                class="btn btn-secondary"
                @click="addEinheitEigenschaft(index, einheit.id)"
              >
                <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
              </button>
            </div>
          </div>

          <hr class="my-4" />
        </div>
      </div>

      <button type="button" class="btn btn-secondary" @click="addEinheit()">
        <font-awesome-icon icon="plus" /> Einheit hinzufügen
      </button>
    </div>

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div class="mb-3" v-if="newArtikel.artikel.artikelartid == 2">
      <hr class="my-3" />
      <p class="fw-bold">Varianten</p>

      <div class="mb-3">
        <p class="fw-bold mb-3">Varianten Eigenschaften</p>

        <div class="row mb-3">
          <div class="col-2">
            <span>Eigenschaft</span>
          </div>

          <div class="col-8">
            <span>Werte</span>
          </div>

          <div class="col-2"></div>
        </div>

        <div
          v-for="(eigenschaft, index) in newArtikel.variantenEigenschaften"
          :key="index"
        >
          <div class="row mb-3">
            <div class="col-2">
              <div>
                <input
                  type="text"
                  v-model="eigenschaft.key"
                  class="form-control"
                  :id="'key-' + index"
                  placeholder="Farbe"
                  required
                  @input="variantenEigenschaftEditKey(index)"
                />
              </div>
            </div>

            <div class="col-8">
              <div class="row">
                <div class="col-5">Wert</div>
                <div class="col-5">Artikelnummer Erweiterung</div>
                <div class="col-2"></div>
              </div>

              <div
                class="row mb-2"
                v-for="(wert, windex) in eigenschaft.values"
                :key="'key-' + index + '-' + windex"
              >
                <div class="col-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="wert.value"
                    placeholder="Rot"
                    required
                    @input="variantenEigenschaftEditValue(index, windex)"
                  />
                </div>

                <div class="col-5">
                  <input
                    type="text"
                    class="form-control"
                    v-model="wert.artikelnummerextension"
                    placeholder="04"
                    @input="
                      variantenEigenschaftEditArtikelnummerextionsion(
                        index,
                        windex
                      )
                    "
                  />
                </div>

                <div class="col-2">
                  <button
                    type="button"
                    class="btn text-secondary"
                    title="Entfernen"
                    @click="removeVarianteEigenschaftValue(index, windex)"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="addVarianteEigenschaftValue(index)"
                >
                  <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
                </button>
              </div>
            </div>

            <div class="col-2">
              <button
                type="button"
                class="btn text-secondary"
                title="Entfernen"
                @click="removeVarianteEigenschaft(index)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="btn btn-secondary"
            @click="addVarianteEigenschaft()"
          >
            <font-awesome-icon icon="plus" /> Eigenschaft hinzufügen
          </button>
        </div>
      </div>

      <div v-if="newArtikel.variantenEigenschaften.length > 0" class="mb-3" v>
        <div class="btn-group mb-3" role="group" aria-label="Basic example">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="generateArtikelVarianten()"
          >
            Varianten generieren
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="addArtikelVariante()"
          >
            Variante hinzufügen
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="deleteAllArtikelVarianten()"
          >
            Alle löschen
          </button>
        </div>

        <div class="mb-3">
          <div
            v-for="(variante, index) in newArtikel.varianten"
            :key="index"
            class="row"
          >
            <div class="col-11">
              <ArtikelvarianteRow
                :artikelnummer="newArtikel.artikel.artikelNr"
                :artikeltitel="newArtikel.artikel.titel"
                :artikel="variante"
                :varianten-eigenschaften="newArtikel.variantenEigenschaften"
                :index="index"
              />
            </div>
            <div class="col-1">
              <button
                type="button"
                class="btn text-secondary"
                title="Entfernen"
                @click="removeArtikelVariante(index)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- VERBUNDARTIKEL -->
    <div class="mb-3" v-if="newArtikel.artikel.artikelartid == 3">
      <hr class="my-3" />
      <p class="fw-bold">Artikel</p>

      <div class="row mb-3">
        <div class="col-5">
          <span>Artikel</span>
        </div>

        <div class="col-3">
          <span>Anzahl</span>
        </div>

        <div class="col-3">
          <span>Einheit</span>
        </div>

        <div class="col-1"></div>
      </div>

      <div
        v-for="(artikel, index) in newArtikel.artikel.verbundArtikel"
        :key="artikel.id"
        class="row mb-3"
      >
        <div class="col-5">
          <!-- <input type="text" class="form-control" :id="'artikel'"> -->
          <AutocompletInputSelect
            :id="'artikel-titel-' + index"
            ref="artikelTitelInput"
            :select-list="artikelNameList"
            :inputValue="''"
            :index="index"
            @set-value="setVerbundartikelByName"
          />
        </div>

        <div class="col-3">
          <input type="number" class="form-control" v-model="artikel.anzahl" />
        </div>

        <div class="col-3">
          <select class="form-select" v-model="artikel.artikelEinheitid">
            <option value="0">Einheit auswählen...</option>
            <option
              v-for="einheit in newArtikel.verbundArtikel[index]?.einheiten"
              :value="einheit.artikelEinheitid"
            >
              {{
                artikeleinheiten.find((a) => a.id == einheit.artikelEinheitid)
                  ?.name
              }}
            </option>
          </select>
        </div>

        <div class="col-1">
          <button
            type="button"
            class="btn text-secondary"
            title="Entfernen"
            @click="removeVerbundArtikel(index)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-secondary"
        @click="addVerbundArtikel()"
      >
        <font-awesome-icon icon="plus" /> Artikel hinzufügen
      </button>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>

    <hr class="my-4" />

    <div class="d-flex justify-content-end py-3">
      <button
        type="button"
        @click="cancel()"
        class="btn btn-outline-secondary me-2 float-end"
      >
        Abbrechen
      </button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>

  <hr />

  <code>
    {{ JSON.stringify(newArtikel.artikel, null, 2) }}
  </code>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, reactive } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
  import { ActionTypes as ArtikeltemplateActionTypes } from '@/store/modules/Artikeltemplates/actions';
  import { v4 as uuidv4 } from 'uuid';

  import {
    Artikel,
    ArtikelEigenschaft,
    ArtikelArtikelEinheit,
    ArtikelVariantenEigenschaft,
    ArtikelEinheit,
    ArtikelEinheitEigenschaft,
    CreateVariantenArtikelRequest,
    VerbundArtikel,
    ArtikelTag,
  } from '@/models/ArtikelModels';
  import {
    EditVariantenEigenschaft,
    EditVariantenEigenschaftValue,
  } from '@/models/ArtikelModels';
  import {
    Artikeltemplate,
    ArtikeltemplateVariantenEigenschaft,
  } from '@/models/ArtikeltemplateModels';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import ArtikelvarianteRow from '@/components/Artikel/ArtikelvarianteRow.vue';
  import AutocompletInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import ArtikelTagSelectModal from '@/components/Artikel/ArtikelTagSelectModal.vue';

  export default defineComponent({
    name: 'CreateArtikelView',

    components: {
      LoadingSpinner,
      ArtikelvarianteRow,
      AutocompletInputSelect,
      ArtikelTagSelectModal,
    },

    setup() {
      const store = useStore();
      const router = useRouter();

      const loading = computed(() => store.getters.status.createArtikelLoading);
      const errorMsg = computed(() => store.getters.status.artikelErrorMsg);

      const artikelarten = computed(() => store.getters.artikelarten);
      const artikeleinheiten = computed(() => store.getters.artikeleinheiten);
      const artikelartikeleinheiten = computed(() => {
        var result = new Array<ArtikelArtikelEinheit>();
        for (var einheit of artikeleinheiten.value) {
          var value = new ArtikelArtikelEinheit();
          value.id = einheit.id;
          value.artikelEinheit = einheit;
          value.artikelEinheitid = einheit.id;
          result.push(value);
        }

        return result;
      });

      const tags = computed(() => store.getters.tags);

      const artikeltemplates = computed(() => store.getters.artikeltemplates);
      const selectedArtikeltemplate = ref<Artikeltemplate | null>(null);

      const artikel = computed(() => store.getters.artikel);
      const artikelNameList = computed(() =>
        artikel.value
          .filter((a) => a.artikelartid == 1)
          .map((obj: Artikel) => `${obj['titel']}`)
      );

      const newArtikel = reactive({
        artikel: new Artikel(),
        variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
        defaulteinheit: -1,
        varianten: new Array<Artikel>(),
        verbundArtikel: new Array<Artikel>(),
      });

      function setSelectedTags(data: Array<number>) {
        newArtikel.artikel.tags = new Array<ArtikelTag>();
        for (var value of data) {
          const newValue = new ArtikelTag();
          newValue.artikelid = newArtikel.artikel.id;
          newValue.tagid = value;
          newArtikel.artikel.tags.push(newValue);
        }

        if (newArtikel.varianten.length > 0) {
          for (var variante of newArtikel.varianten) {
            variante.tags = newArtikel.artikel.tags;
          }
        }
      }

      function addEigenschaft() {
        newArtikel.artikel.eigenschaften.push(new ArtikelEigenschaft());
      }

      function removeEigenschaft(index: number) {
        newArtikel.artikel.eigenschaften.splice(index, 1);
      }

      function addEinheit() {
        var einheit = new ArtikelArtikelEinheit();
        einheit.id = newArtikel.artikel.einheiten.length + 1;
        einheit.pos = newArtikel.artikel.einheiten.length + 1;
        newArtikel.artikel.einheiten.push(einheit);
      }

      function removeEinheit(index: number) {
        newArtikel.artikel.einheiten.splice(index, 1);
      }

      function setEinheitArtikelEinheit(index: number, einheitId: number) {
        const einheit = artikeleinheiten.value.find(
          (ae: ArtikelEinheit) => ae.id == einheitId
        );
        if (einheit != null) {
          newArtikel.artikel.einheiten[index].artikelEinheit = einheit;
        }
      }

      function einheitPosUp(index: number) {
        newArtikel.artikel.einheiten[index - 1].pos++;
        newArtikel.artikel.einheiten[index].pos--;
      }

      function einheitPosDown(index: number) {
        newArtikel.artikel.einheiten[index + 1].pos--;
        newArtikel.artikel.einheiten[index].pos++;
      }

      function addEinheitEigenschaft(index: number, einheitid: number) {
        const eigenschaft = new ArtikelEinheitEigenschaft();
        eigenschaft.artikelArtikelEinheitid = einheitid;
        newArtikel.artikel.einheiten[index].eigenschaften.push(eigenschaft);
      }

      function removeEinheitEigenschaft(
        index: number,
        eigenschaft: ArtikelEinheitEigenschaft
      ) {
        var idx = newArtikel.artikel.einheiten[index].eigenschaften.findIndex(
          (e: ArtikelEinheitEigenschaft) =>
            e.key == eigenschaft.key &&
            e.value == eigenschaft.value &&
            e.artikelArtikelEinheitid == eigenschaft.artikelArtikelEinheitid
        );
        newArtikel.artikel.einheiten[index].eigenschaften.splice(idx, 1);
      }

      function addVarianteEigenschaft() {
        newArtikel.variantenEigenschaften.push(
          new EditVariantenEigenschaft('')
        );

        if (newArtikel.varianten.length > 0) {
          const index = newArtikel.variantenEigenschaften.length - 1;
          newArtikel.varianten.forEach((variante) => {
            var uuid = uuidv4();
            variante.variantenEigenschaften.push(
              new ArtikelVariantenEigenschaft(
                0,
                0,
                uuid,
                newArtikel.variantenEigenschaften[index].key,
                '',
                ''
              )
            );
          });
        }
      }

      function removeVarianteEigenschaft(index: number) {
        if (newArtikel.varianten.length > 0) {
          const eigenschaft = newArtikel.variantenEigenschaften[index];
          newArtikel.varianten.forEach((variante) => {
            const vindex = variante.variantenEigenschaften.findIndex(
              (v) => v.key == eigenschaft.key
            );
            variante.variantenEigenschaften.splice(vindex, 1);

            // Artikelnummer anpassen
            let newArtikelnummer = newArtikel.artikel.artikelNr;
            let newArtikeltitel = newArtikel.artikel.titel;
            variante.variantenEigenschaften.forEach((v) => {
              newArtikelnummer += v.artikelnummerextension;
              newArtikeltitel += ' ' + v.value;
            });
            variante.artikelNr = newArtikelnummer;
            variante.titel = newArtikeltitel;
          });
        }

        newArtikel.variantenEigenschaften.splice(index, 1);
      }

      function addVarianteEigenschaftValue(varianteindex: number) {
        newArtikel.variantenEigenschaften[varianteindex].values.push(
          new EditVariantenEigenschaftValue(uuidv4(), '', '')
        );
      }

      function removeVarianteEigenschaftValue(
        varianteindex: number,
        index: number
      ) {
        newArtikel.variantenEigenschaften[varianteindex].values.splice(
          index,
          1
        );
      }

      function getAllCombinations(
        input: Array<EditVariantenEigenschaft>
      ): Array<any> {
        const eigenschaften: any = {};
        for (let eigenschaft of input) {
          eigenschaften[eigenschaft.key] = [];
          for (var option of eigenschaft.values) {
            eigenschaften[eigenschaft.key].push(
              new EditVariantenEigenschaftValue(
                option.identifier,
                option.value,
                option.artikelnummerextension
              )
            );
          }
        }

        const keys = Object.keys(eigenschaften);
        const combinations: Array<any> = [];

        function generateCombinations(index: number, currentCombination: any) {
          if (index == keys.length) {
            combinations.push({ ...currentCombination });
            return;
          }

          const key = keys[index];
          const values = eigenschaften[key];

          for (const value of values) {
            currentCombination[key] = value;
            generateCombinations(index + 1, currentCombination);
          }
        }

        generateCombinations(0, {});

        return combinations;
      }

      function generateArtikelVarianten() {
        newArtikel.varianten = new Array<Artikel>();

        const combinations = getAllCombinations(
          newArtikel.variantenEigenschaften
        );

        combinations.forEach((comp) => {
          var variante = new Artikel();

          variante.titel = newArtikel.artikel.titel;
          variante.artikelNr = newArtikel.artikel.artikelNr;
          variante.eigenschaften = newArtikel.artikel.eigenschaften;
          variante.einheiten = newArtikel.artikel.einheiten;
          variante.tags = newArtikel.artikel.tags;
          variante.beschreibung = newArtikel.artikel.beschreibung;
          variante.isVariation = 1;

          for (const key in comp) {
            const eigenschaft = new ArtikelVariantenEigenschaft(
              0,
              0,
              comp[key].identifier,
              key,
              comp[key].value,
              comp[key].artikelnummerextension
            );
            variante.variantenEigenschaften.push(eigenschaft);
            variante.artikelNr += eigenschaft.artikelnummerextension;
            variante.titel += ' ' + eigenschaft.value;
          }

          newArtikel.varianten.push(variante);
        });
      }

      function addArtikelVariante() {
        var variante = new Artikel();
        variante.titel = newArtikel.artikel.titel;
        variante.artikelNr = newArtikel.artikel.artikelNr;
        variante.einheiten = newArtikel.artikel.einheiten;
        variante.tags = newArtikel.artikel.tags;
        variante.isVariation = 1;
        variante.eigenschaften = newArtikel.artikel.eigenschaften;

        for (var prop of newArtikel.variantenEigenschaften) {
          variante.variantenEigenschaften.push(
            new ArtikelVariantenEigenschaft(0, 0, '', prop.key, '', '')
          );
        }

        newArtikel.varianten.push(variante);
      }

      function removeArtikelVariante(index: number) {
        newArtikel.varianten.splice(index, 1);
      }

      function deleteAllArtikelVarianten() {
        newArtikel.varianten = new Array<Artikel>();
      }

      function addVerbundArtikel() {
        newArtikel.artikel.verbundArtikel.push(new VerbundArtikel());
      }

      function removeVerbundArtikel(index: number) {
        newArtikel.artikel.verbundArtikel.splice(index, 1);
      }

      function setArtikelart() {
        if (newArtikel.artikel.artikelartid == 3) {
          newArtikel.artikel.einheiten = new Array<ArtikelArtikelEinheit>();
          const newEinheit = new ArtikelArtikelEinheit();
          newEinheit.default = 1;
          newArtikel.artikel.einheiten.push(newEinheit);
        } else {
          newArtikel.artikel.verbundArtikel = new Array<VerbundArtikel>();
        }
      }

      return {
        store,
        router,
        loading,
        errorMsg,
        artikelarten,
        artikeleinheiten,
        artikelartikeleinheiten,
        tags,
        artikeltemplates,
        selectedArtikeltemplate,
        artikel,
        artikelNameList,
        newArtikel,
        setSelectedTags,
        addEigenschaft,
        removeEigenschaft,

        addEinheit,
        removeEinheit,
        setEinheitArtikelEinheit,
        einheitPosUp,
        einheitPosDown,
        addEinheitEigenschaft,

        removeEinheitEigenschaft,
        addVarianteEigenschaft,
        removeVarianteEigenschaft,
        addVarianteEigenschaftValue,
        removeVarianteEigenschaftValue,
        generateArtikelVarianten,
        addArtikelVariante,
        removeArtikelVariante,
        deleteAllArtikelVarianten,
        addVerbundArtikel,
        removeVerbundArtikel,
        setArtikelart,
      };
    },

    methods: {
      newArtikelEditArtikeltitel() {
        if (this.newArtikel.varianten.length > 0) {
          this.newArtikel.varianten.forEach((variante) => {
            let newArtikeltitel = this.newArtikel.artikel.titel;
            variante.variantenEigenschaften.forEach((v) => {
              newArtikeltitel += ' ' + v.value;
            });
            variante.titel = newArtikeltitel;
          });
        }
      },

      newArtikelEditArtikelnummer() {
        if (this.newArtikel.varianten.length > 0) {
          this.newArtikel.varianten.forEach((variante) => {
            let newArtikelnummer = this.newArtikel.artikel.artikelNr;
            variante.variantenEigenschaften.forEach((v) => {
              newArtikelnummer += v.artikelnummerextension;
            });
            variante.artikelNr = newArtikelnummer;
          });
        }
      },

      variantenEigenschaftEditKey(index: number) {
        if (this.newArtikel.varianten.length > 0) {
          this.newArtikel.varianten.forEach((variante) => {
            variante.variantenEigenschaften[index].key =
              this.newArtikel.variantenEigenschaften[index].key;
          });
        }
      },

      variantenEigenschaftEditValue(index: number, vindex: number) {
        if (this.newArtikel.varianten.length > 0) {
          this.newArtikel.varianten.forEach((variante) => {
            if (
              variante.variantenEigenschaften[index].identifier ==
              this.newArtikel.variantenEigenschaften[index].values[vindex]
                .identifier
            ) {
              variante.variantenEigenschaften[index].value =
                this.newArtikel.variantenEigenschaften[index].values[
                  vindex
                ].value;

              // Titel anpassen
              let newTitel = this.newArtikel.artikel.titel;
              variante.variantenEigenschaften.forEach((v) => {
                newTitel += ' ' + v.value;
              });
              variante.titel = newTitel;
            }
          });
        }
      },

      variantenEigenschaftEditArtikelnummerextionsion(
        index: number,
        vindex: number
      ) {
        if (this.newArtikel.varianten.length > 0) {
          this.newArtikel.varianten.forEach((variante) => {
            if (
              variante.variantenEigenschaften[index].identifier ==
              this.newArtikel.variantenEigenschaften[index].values[vindex]
                .identifier
            ) {
              variante.variantenEigenschaften[index].artikelnummerextension =
                this.newArtikel.variantenEigenschaften[index].values[
                  vindex
                ].artikelnummerextension;

              // Artikelnummer anpassen
              let newArtikelnummer = this.newArtikel.artikel.artikelNr;
              variante.variantenEigenschaften.forEach((v) => {
                newArtikelnummer += v.artikelnummerextension;
              });
              variante.artikelNr = newArtikelnummer;
            }
          });
        }
      },

      setArtikeltemplate() {
        if (this.selectedArtikeltemplate == null) {
          this.clearInputs();
        } else {
          const template = JSON.parse(
            JSON.stringify(this.selectedArtikeltemplate)
          ) as Artikeltemplate;
          this.newArtikel.artikel.titel = template.artikeltitel;
          this.newArtikel.artikel.artikelartid = template.artikelartid;
          this.newArtikel.artikel.artikelNr = template.artikelnummer;
          this.newArtikel.artikel.beschreibung = template.artikelbeschreibung;

          for (var tag of template.tags) {
            var newTag = new ArtikelTag();
            newTag.artikelid = this.newArtikel.artikel.id;
            newTag.tagid = tag.tagid;
            this.newArtikel.artikel.tags.push(newTag);
          }

          this.newArtikel.artikel.eigenschaften = JSON.parse(
            JSON.stringify(template.eigenschaften)
          ) as Array<ArtikelEigenschaft>;
          for (var eigen1 of this.newArtikel.artikel.eigenschaften) {
            eigen1.id = 0;
          }

          // Einheiten
          this.newArtikel.artikel.einheiten =
            new Array<ArtikelArtikelEinheit>();
          for (var e of template.einheiten) {
            var einheit = new ArtikelArtikelEinheit();
            einheit.id = this.newArtikel.artikel.einheiten.length + 1;

            einheit.default = e.default;
            if (e.default == 1) {
              this.newArtikel.defaulteinheit = einheit.id;
            }
            einheit.artikelEinheit = e.artikelEinheit;
            einheit.artikelid = this.newArtikel.artikel.id;
            einheit.anzahl = e.anzahl;
            einheit.pos = e.pos;
            einheit.einzelnEinlagern = e.einzelnEinlagern;

            einheit.artikelEinheitid = e.artikelEinheitid;
            var artikeleinheit = this.artikeleinheiten.find(
              (a) => a.id == e.artikelEinheitid
            );
            einheit.artikelEinheit =
              artikeleinheit != null ? artikeleinheit : new ArtikelEinheit();

            einheit.eigenschaften = new Array<ArtikelEinheitEigenschaft>();
            if (e.eigenschaften.length > 0) {
              for (var eigenschaft of e.eigenschaften) {
                var neweigenschaft = new ArtikelEinheitEigenschaft();
                neweigenschaft.id = 0;
                neweigenschaft.artikelArtikelEinheitid = einheit.id;
                neweigenschaft.key = eigenschaft.key;
                neweigenschaft.value = eigenschaft.value;
                einheit.eigenschaften.push(neweigenschaft);
              }
            }
            this.newArtikel.artikel.einheiten.push(einheit);

            var artikelartikeleinheitIndex =
              this.artikelartikeleinheiten.findIndex(
                (a) => a.artikelEinheitid == einheit.artikelEinheitid
              );
            if (artikelartikeleinheitIndex != -1) {
              this.artikelartikeleinheiten.splice(
                artikelartikeleinheitIndex,
                1,
                einheit
              );
            }
          }

          // Varianteneigenschaften
          const uniquekeys: Array<string> = [
            ...new Set<string>(
              template.variantenEigenschaften.map(
                (i: ArtikeltemplateVariantenEigenschaft) => i.key
              )
            ),
          ];
          for (var key of uniquekeys) {
            const eve = new EditVariantenEigenschaft(key);
            const eigenschaften = template.variantenEigenschaften.filter(
              (e: ArtikeltemplateVariantenEigenschaft) => e.key == key
            );
            for (var eigen of eigenschaften) {
              eve.values.push(
                new EditVariantenEigenschaftValue(
                  eigen.identifier,
                  eigen.value,
                  eigen.artikelnummerextension
                )
              );
            }

            this.newArtikel.variantenEigenschaften.push(eve);
          }

          this.newArtikel.varianten = new Array<Artikel>();
        }
      },

      setVerbundartikelByName(name: string, index: number) {
        const artikel = this.artikel.find((a) => a.titel == name);
        if (artikel != null) {
          if (this.newArtikel.verbundArtikel[index] == null) {
            this.newArtikel.verbundArtikel.push(artikel);
          } else {
            this.newArtikel.verbundArtikel.splice(index, 1, artikel);
          }

          this.newArtikel.artikel.verbundArtikel[index].verbundArtikelid =
            artikel.id;
        }
      },

      clearInputs() {
        this.newArtikel.artikel = new Artikel();
        this.newArtikel.variantenEigenschaften =
          new Array<EditVariantenEigenschaft>();
        this.newArtikel.defaulteinheit = -1;
        this.newArtikel.varianten = new Array<Artikel>();
        this.newArtikel.verbundArtikel = new Array<Artikel>();
      },

      cancel() {
        this.clearInputs();
        this.router.push({ name: 'Artikel' });
      },

      createArtikel() {
        // add varianteneigenschaften
        if (this.newArtikel.variantenEigenschaften.length > 0) {
          for (var eigenschaft of this.newArtikel.variantenEigenschaften) {
            for (var value of eigenschaft.values) {
              this.newArtikel.artikel.variantenEigenschaften.push(
                new ArtikelVariantenEigenschaft(
                  0,
                  0,
                  value.identifier,
                  eigenschaft.key,
                  value.value,
                  value.artikelnummerextension
                )
              );
            }
          }
        }

        // set default einheit
        for (var einheit of this.newArtikel.artikel.einheiten) {
          einheit.id = 0;
          if (einheit.id == this.newArtikel.defaulteinheit) {
            einheit.default = 1;
          }
        }

        if (this.newArtikel.artikel.artikelartid == 2) {
          // for (var variante of this.newArtikel.varianten) {

          // }
          var data = new CreateVariantenArtikelRequest(
            this.newArtikel.artikel,
            this.newArtikel.varianten
          );
          this.store
            .dispatch(ArtikelActionTypes.CreateVariantenArtikel, data)
            .then(() => {
              if (this.errorMsg == '') {
                this.cancel();
              }
            });
        }
        // if (this.newArtikel.artikel.artikelartid == 1) {
        else {
          this.store
            .dispatch(ArtikelActionTypes.CreateArtikel, this.newArtikel.artikel)
            .then(() => {
              if (this.errorMsg == '') {
                this.cancel();
              }
            });
        }
      },
    },

    mounted() {
      if (this.artikeltemplates.length == 0) {
        this.store.dispatch(
          ArtikeltemplateActionTypes.GetArtikeltemplate,
          undefined
        );
      }

      if (this.artikelarten.length == 0) {
        this.store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined);
      }

      if (this.artikeleinheiten.length == 0) {
        this.store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined);
      }
    },
  });
</script>

<style scoped>
  .footerbar {
    position: absolute;
    bottom: 0;
  }

  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>
