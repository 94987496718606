export class Eigenschaft {
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export class VariantenEigenschaft {
  identifier: string;
  key: string;
  value: string;
  artikelnummerextension: string;

  constructor(identifier: string, key: string, value: string, artikelnummerextension: string) {
    this.identifier = identifier;
    this.key = key;
    this.value = value;
    this.artikelnummerextension = artikelnummerextension;
  }
}

export class EditVariantenEigenschaft {
  key: string;
  values: Array<EditVariantenEigenschaftValue>;

  constructor(key: string) {
    this.key = key;
    this.values = new Array<EditVariantenEigenschaftValue>();
  }
}

export class EditVariantenEigenschaftValue {
  id: number;
  identifier: string;
  value: string;
  artikelnummerextension: string;

  constructor(idetifier: string, value: string, artikelnummerextension: string, id: number = 0) {
    this.id = id;
    this.identifier = idetifier;
    this.value = value;
    this.artikelnummerextension = artikelnummerextension;
  }
}

export class ArtikelEigenschaft {
  id: number;
  artikelid: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.artikelid = 0;
    this.key = '';
    this.value = '';
  }
}

export class ArtikelVariantenEigenschaft {
  id: number;
  artikelid: number;
  identifier: string;
  key: string;
  value: string;
  artikelnummerextension: string;

  constructor(id: number, artikelid: number, identifier: string, key: string, value: string, artikelnummerextension: string) {
    this.id = id;
    this.artikelid = artikelid;
    this.identifier = identifier;
    this.key = key;
    this.value = value;
    this.artikelnummerextension = artikelnummerextension;
  }
}

export class ArtikelArtikelEinheit {
  id: number;
  default: number;
  artikelid: number;
  artikelEinheitid: number;
  artikelEinheit: ArtikelEinheit;
  anzahl: number;
  pos: number;
  einzelnEinlagern: boolean;
  eigenschaften: Array<ArtikelEinheitEigenschaft>;

  constructor() {
    this.id = 0;
    this.default = 0;
    this.artikelid = 0;
    this.artikelEinheitid = -1;
    this.artikelEinheit = new ArtikelEinheit();
    this.anzahl = 1;
    this.pos = 1;
    this.einzelnEinlagern = false;
    this.eigenschaften = new Array<ArtikelEinheitEigenschaft>();
  }
}

export class ArtikelEinheitEigenschaft {
  id: number;
  artikelArtikelEinheitid: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.artikelArtikelEinheitid = 0;
    this.key = '';
    this.value = '';
  }
}

export class VerbundArtikel {
  id: number;
  artikelid: number;
  verbundArtikelid: number;
  artikelEinheitid: number;
  anzahl: number;

  constructor() {
    this.id = 0;
    this.artikelid = 0;
    this.verbundArtikelid = 0;
    this.artikelEinheitid = 0;
    this.anzahl = 0;
  }
}

export class Tag {
  id: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.key = '';
    this.value = '';
  }
}

export class ArtikelTag {
  id: number;
  artikelid: number;
  tagid: number;

  constructor() {
    this.id = 0;
    this.artikelid = 0;
    this.tagid = 0;
  }
}

export class Artikel {
  id: number;
  artikelNr: string;
  titel: string;
  artikelartid: number;
  beschreibung: string;
  einheiten: Array<ArtikelArtikelEinheit>;
  einheitEigenschaften: Array<ArtikelEinheitEigenschaft>;
  eigenschaften: Array<ArtikelEigenschaft>;
  variantenEigenschaften: Array<ArtikelVariantenEigenschaft>;
  verbundArtikel: Array<VerbundArtikel>;
  tags: Array<ArtikelTag>;
  isVariation: number;
  ownerId: number;
  isActive: number;
  isDeleted: number;

  constructor() {
    this.id = 0;
    this.artikelNr = '';
    this.titel = '';
    this.artikelartid = 1;
    this.beschreibung = '';
    this.einheiten = new Array<ArtikelArtikelEinheit>();
    this.einheitEigenschaften = new Array<ArtikelEinheitEigenschaft>();
    this.eigenschaften = new Array<ArtikelEigenschaft>();
    this.variantenEigenschaften = new Array<ArtikelVariantenEigenschaft>();
    this.verbundArtikel = new Array<VerbundArtikel>();
    this.tags = new Array<ArtikelTag>();
    this.isVariation = 0;
    this.ownerId = 0;
    this.isActive = 0;
    this.isDeleted = 0;
  }

  updateData(data: Artikel) {
    this.artikelNr = data.artikelNr;
    this.titel = data.titel;
    this.artikelartid = data.artikelartid;
    this.beschreibung = data.beschreibung;
    this.einheiten = data.einheiten;
    this.einheitEigenschaften = data.einheitEigenschaften;
    this.eigenschaften = data.eigenschaften;
    this.variantenEigenschaften = data.variantenEigenschaften;
    this.verbundArtikel = data.verbundArtikel;
    this.tags = data.tags;
    this.isVariation = data.isVariation;
    this.ownerId = data.ownerId;
    this.isActive = data.isActive;
    this.isDeleted = data.isDeleted;
  }
}

export class CreateVariantenArtikelRequest {
  artikel: Artikel;
  varianten: Array<Artikel>;

  constructor(artikel: Artikel, varianten: Array<Artikel>) {
    this.artikel = artikel;
    this.varianten = varianten;
  }
}

export class ArtikelTree {
  artikel: Artikel;
  varianten: Array<Artikel>;

  constructor(artikel: Artikel) {
    this.artikel = artikel;
    this.varianten = new Array<Artikel>();
  }
}

export class CreateArtikelRequest {
  artikelNr: string;
  titel: string;
  artikelartid: number;
  beschreibung: string;
  einheit: number;
  einheiten: Array<number>;
  einheitEigenschaften: Array<ArtikelEinheitEigenschaft>;
  isVariation: number;
  ownerId: number;
  eigenschaften: Array<Eigenschaft>;
  variantenEigenschaften: Array<VariantenEigenschaft>;
  varianten: Array<CreateArtikelRequest>;

  constructor() {
    this.artikelNr = '';
    this.titel = '';
    this.artikelartid = 1;
    this.beschreibung = '';
    this.einheit = 1;
    this.einheiten = new Array<number>();
    this.einheitEigenschaften = new Array<ArtikelEinheitEigenschaft>();
    this.eigenschaften = new Array<Eigenschaft>();
    this.variantenEigenschaften = new Array<VariantenEigenschaft>();
    this.isVariation = 0;
    this.ownerId = 0;
    this.varianten = new Array<CreateArtikelRequest>();
  }
}

export class UpdateArtikelRequest {
  id: number;
  artikelNr: string;
  titel: string;
  artikelartid: number;
  beschreibung: string;
  einheit: number;
  einheiten: Array<number>;
  einheitEigenschaften: Array<ArtikelEinheitEigenschaft>;
  eigenschaften: Array<Eigenschaft>;
  variantenEigenschaften: Array<VariantenEigenschaft>;
  isVariation: number;
  ownerId: number;
  isActive: number;
  isDeleted: number;
  varianten: Array<UpdateArtikelRequest>;

  constructor() {
    this.id = 0;
    this.artikelNr = '';
    this.titel = '';
    this.artikelartid = 1;
    this.beschreibung = '';
    this.einheit = 1;
    this.einheiten = new Array<number>();
    this.einheitEigenschaften = new Array<ArtikelEinheitEigenschaft>();
    this.eigenschaften = new Array<Eigenschaft>();
    this.variantenEigenschaften = new Array<VariantenEigenschaft>();
    this.isVariation = 0;
    this.ownerId = 0;
    this.isActive = 0;
    this.isDeleted = 0;
    this.varianten = new Array<UpdateArtikelRequest>();
  }
}

export class Artikelart {
  id: number;
  name: string;

  constructor() {
    this.id = 0;
    this.name = '';
  }
}

// export class ArtikelEinheitEigenschaft {
//   key: string
//   value: string
//   artikeleinheitid: number

//   constructor (key: string, value: string, artikeleinheitid: number) {
//     this.key = key;
//     this.value = value;
//     this.artikeleinheitid = artikeleinheitid;
//   }
// }

export class ArtikelEinheit {
  id: number;
  name: string;
  kurz: string;

  constructor() {
    this.id = -1;
    this.name = '';
    this.kurz = '';
  }
}

export class ImportArtikel {
  artikel: Artikel;
  existing: boolean;
  task: number; // 0 = ignore, 1 = update, 2 = copy
  hasError: boolean;

  constructor(artikel: Artikel) {
    this.artikel = artikel;
    this.existing = false;
    this.task = 1;
    this.hasError = false;
  }
}
