<template>
  <div class="row mb-3">
    <div class="col">
      <AutocompleteInputSelect
        :id="'position-artikelnr-' + index"
        ref="positionArtikelnrInputRef"
        :select-list="artikelNummerList"
        :inputValue="pos.artikelnr"
        :index="index"
        @set-value="setPositionByArtikelnr"
      />
    </div>

    <div class="col">
      <AutocompleteInputSelect
        :id="'position-artikeltitel-' + index"
        ref="positionArtikeltitelInputRef"
        :select-list="artikelTitelList"
        :inputValue="pos.artikeltitel"
        :index="index"
        @set-value="setPositionByArtikeltitel"
      />
    </div>

    <div class="col">
      <input type="number" class="form-control" :id="'pos-anzahl-' + index" v-model="pos.anzahl" />
    </div>

    <div class="col">
      <select class="form-select" name="artikel-einheit" v-model="pos.einheitid">
        <option :value="0">Einheit auswählen...</option>
        <option v-for="ae in availableArtikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed, ref } from 'vue';
  import { useStore } from '@/store';

  import { Artikel, ArtikelArtikelEinheit } from '@/models/ArtikelModels';

  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import { EditAuftragPos } from '@/models/AuftragModels';

  const { pos, index } = defineProps({
    pos: {
      type: Object as PropType<EditAuftragPos>,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  });

  const store = useStore();

  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);
  const availableArtikeleinheiten = ref(artikeleinheiten.value);

  const artikel = computed(() => store.getters.artikel);
  const artikelTitelList = computed(() => artikel.value.map((obj: Artikel) => obj['titel']));
  const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj['artikelNr']));

  const positionArtikeltitelInputRef = ref();
  const positionArtikelnrInputRef = ref();

  function setPositionByArtikelnr(artikelnummer: string) {
    const art = artikel.value.find((a: Artikel) => a.artikelNr == artikelnummer);

    if (art != null) {
      setPositionArtikel(art);
      positionArtikeltitelInputRef.value.inputvalue = art.titel;
    }
  }

  function setPositionByArtikeltitel(artikeltitel: string) {
    const art = artikel.value.find((a: Artikel) => a.titel == artikeltitel);

    if (art != null) {
      setPositionArtikel(art);
      positionArtikelnrInputRef.value.inputvalue = art.artikelNr;
    }
  }

  function setPositionArtikel(artikel: Artikel) {
    pos.id = 0;
    pos.artikelnr = artikel.artikelNr;
    pos.artikeltitel = artikel.titel;

    let defaultEinheit = artikel.einheiten.find((e: ArtikelArtikelEinheit) => e.default == 1);
    if (defaultEinheit == null) {
      defaultEinheit = artikel.einheiten[0];
    }
    pos.einheitid = defaultEinheit != null ? defaultEinheit.artikelEinheitid : 1;
  }
</script>
