<template>
  <div class="mb-2 bg-white shadow-sm">
    <div class="row mx-1 py-2">
      <div class="col-1 my-auto">{{ bestand.artikelnummer }}</div>
      <div class="col-3 my-auto">{{ bestand.artikeltitel }}</div>
      <div class="col-1 my-auto">{{ bestand.barcode }}</div>

      <!-- <div class="col-1 my-auto">{{ bestand.anzahl }}</div>
      <div class="col-1 my-auto">{{ einheit?.name }}</div> -->

      <div class="col-2 my-auto d-flex flex-wrap">
        <span v-for="ein in bestand.einheiten.sort((a: ArtikelbestandArtikelEinheit, b: ArtikelbestandArtikelEinheit) => a.pos - b.pos)" :key="ein.id">
          <span v-if="ein.anzahl != 0" class="border border-dark py-1 px-2 me-1">{{ ein.anzahl }} {{ getEinheitById(ein.artikelEinheitid).name }}</span>
        </span>
      </div>

      <div class="col-1 my-auto">{{ lagerort?.name }}</div>
      <div class="col-1 my-auto">{{ lagerbereich?.name }}</div>
      <div class="col-1 my-auto">{{ lagerreihe?.reihe }}</div>
      <div class="col-1 my-auto">{{ lagerplatz?.platz }}</div>
      <div class="col-1 my-auto">
        <button class="btn btn-primary m-0 float-end dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <span v-if="deleteBestandLoading">
            <div class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
          </span>

          <font-awesome-icon v-else icon="pen-to-square" class="fa-sm" />
        </button>

        <ul class="dropdown-menu m-0 p-0 rounded-0">
          <li class="dropdown-item" @click="showBestandUmlagernModal()">
            <span v-if="lagerbereich?.lagerbereichartid == 5" role="button">Einlagern</span>
            <span v-else role="button">Umlagern</span>
          </li>
          <li class="dropdown-item" role="button" @click="deleteBestand()">Als verbraucht kennzeichnen</li>

          <li class="dropdown-item" role="button" @click="openBestandDetails()">
            <!-- <router-link class="text-decoration-none text-reset" :to="{name: 'DetailsBestand', params: { id: bestand.id }}">Details</router-link> -->
            Details
          </li>
        </ul>
      </div>
    </div>

    <div v-if="lagerReservierung != null" class="row mx-1 py-1 text-success">
      <div class="col-1 my-auto"></div>
      <div class="col-3 my-auto"></div>
      <div class="col-1 my-auto"></div>
      <div class="col-1 my-auto"></div>
      <div class="col-1 my-auto">Reservierung:</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerort?.name }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerbereich?.name }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerreihe?.reihe }}</div>
      <div class="col-1 my-auto">{{ lagerReservierungLagerplatz?.platz }}</div>
      <div class="col-1 my-auto"></div>
    </div>
  </div>

  <!-- Update Modal -->
  <div
    class="modal fade"
    :id="'bestandUmlagernModal' + bestand.id"
    tabindex="-1"
    :aria-labelledby="'bestandUmlagernModal' + bestand.id"
    aria-hidden="true"
    ref="bestandUmlagernModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white py-2">
          <span class="modal-title my-auto" :id="'bestandUmlagernModalLabel' + bestand.id">
            <span v-if="lagerbereich?.lagerbereichartid == 5">Einlagern</span>
            <span v-else>Umlagern</span>
          </span>

          <button type="button" class="btn-close btn-sm text-white" @click="closeBestandUmlagernModal()"></button>
        </div>

        <div class="modal-body" :id="'bestandUmlagernModalBody' + bestand.id">
          <LoadingSpinner v-if="updateBestandLoading" />

          <div v-else>
            <div>
              <p><strong>Lagerort:</strong> {{ lagerort?.name }}</p>
              <p><strong>Lagerbereich:</strong> {{ lagerbereich?.name }}</p>
              <p><strong>Reihe:</strong> {{ lagerreihe?.reihe }}</p>
              <p><strong>Platz:</strong> {{ lagerplatz?.platz }}</p>
            </div>

            <hr class="my-4" />

            <form @submit.prevent="updateBestandUmlagern()">
              <div class="mb-3">
                <label :for="'lagerortauswahl-' + bestand.id" class="form-label">Lagerort</label>
                <select class="form-select" :id="'lagerortauswahl-' + bestand.id" v-model="updatedBestand.lagerortid" @change="resetBereich()">
                  <option :value="0">Lagerort auswählen...</option>
                  <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort.id">{{ lagerort.name }}</option>
                </select>
              </div>

              <div v-if="updatedBestand.lagerortid != 0" class="mb-3">
                <label :for="'lagerbereichauswahl-' + bestand.id" class="form-label">Lagerbereich</label>
                <select class="form-select" :id="'lagerbereichauswahl-' + bestand.id" v-model="updatedBestand.lagerbereichid">
                  <option :value="0">Lagerbereich auswählen...</option>
                  <option v-for="bereiche in lagerbereiche" :key="bereiche.id" :value="bereiche.id">
                    {{ getLagerbereichInfo(bereiche.ownerid || 0)?.name }} {{ bereiche.name }}
                  </option>
                </select>
              </div>

              <div v-if="updatedBestand.lagerortid != 0 && updatedBestand.lagerbereichid != 0" class="mb-3">
                <label :for="'reiheauswahl-' + bestand.id" class="form-label">Reihe</label>
                <select class="form-select" :id="'reiheauswahl-' + bestand.id" v-model="updatedBestand.lagerreiheid">
                  <option :value="0">Lagerreihe auswählen...</option>
                  <option v-for="reihe in lagerreihen" :key="reihe.id" :value="reihe.id">{{ reihe.reihe }}</option>
                </select>
              </div>

              <div
                v-if="
                  updatedBestand.lagerortid != 0 &&
                  updatedBestand.lagerbereichid != 0 &&
                  updatedBestand.lagerreiheid != 0 &&
                  selectedLagerbereich?.lagerbereichartid != 2
                "
                class="mb-3"
              >
                <label :for="'platzauswahl-' + bestand.id" class="form-label">Platz</label>
                <select class="form-select" :id="'platzauswahl-' + bestand.id" v-model="updatedBestand.lagerplatzid">
                  <option :value="0">Lagerreihe auswählen...</option>
                  <option v-for="platz in lagerplaetze" :key="platz.id" :value="platz.id">{{ platz.platz }}</option>
                </select>
              </div>

              <hr class="my-3" />

              <LoadingSpinner v-if="updateBestandLoading" />

              <div v-if="bestandErrorMsg != ''" class="alert alert-danger">
                {{ bestandErrorMsg }}
              </div>

              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary btn-sm me-3" @click="closeBestandUmlagernModal()">Abbrechen</button>
                <button type="submit" class="btn btn-primary btn-sm">Speichern</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent, computed, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';
  import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

  import { Modal } from 'bootstrap';

  import LoadingSpinner from '../LoadingSpinner.vue';

  import { ArtikelBestand, ArtikelBestandVerbrauchRequest, ArtikelbestandArtikelEinheit } from '@/models/BestandModels';
  import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
  import { ArtikelEinheit } from '@/models/ArtikelModels';
  import { LagerReservierung } from '@/models/LagerReservierungModels';

  export default defineComponent({
    name: 'BestandRow',

    components: {
      LoadingSpinner,
    },

    props: {
      bestand: {
        type: Object as PropType<ArtikelBestand>,
        required: true,
      },
    },

    setup(props) {
      const store = useStore();
      const router = useRouter();

      // const einheit = computed(() => store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == props.bestand.einheitid))
      const einheiten = computed(() => store.getters.artikeleinheiten);
      function getEinheitById(id: number): ArtikelEinheit {
        const response = einheiten.value.find((e: ArtikelEinheit) => e.id == id);
        return response ? response : new ArtikelEinheit();
      }

      const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.bestand.lagerortid));
      const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.bestand.lagerbereichid));
      const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.bestand.lagerreiheid));
      const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.bestand.lagerplatzid));

      const lagerReservierung = computed(() => store.getters.lagerReservierungen.find((lr: LagerReservierung) => lr.bestandid == props.bestand.id));
      const lagerReservierungLagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == lagerReservierung.value?.lagerortid));
      const lagerReservierungLagerbereich = computed(() =>
        store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == lagerReservierung.value?.lagerbereichid)
      );
      const lagerReservierungLagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == lagerReservierung.value?.lagerreiheid));
      const lagerReservierungLagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == lagerReservierung.value?.lagerplatzid));

      const bestandUmlagernModalRef = ref();
      function closeBestandUmlagernModal() {
        Modal.getInstance(bestandUmlagernModalRef.value)?.hide();
      }
      async function showBestandUmlagernModal() {
        if (bestandUmlagernModalRef.value) {
          new Modal(bestandUmlagernModalRef.value).show();
        }
      }

      const updateBestandLoading = computed(() => store.getters.status.updateBestandLoading);
      const bestandErrorMsg = computed(() => store.getters.status.bestandErrorMsg);
      const updatedBestand = ref(JSON.parse(JSON.stringify(props.bestand)));

      if (lagerReservierung.value != null) {
        updatedBestand.value.lagerortid = lagerReservierung.value.lagerortid;
        updatedBestand.value.lagerbereichid = lagerReservierung.value.lagerbereichid;
        updatedBestand.value.lagerreiheid = lagerReservierung.value.lagerreiheid;
        updatedBestand.value.lagerplatzid = lagerReservierung.value.lagerplatzid;
      }

      function openBestandDetails() {
        router.push({ name: 'DetailsBestand', params: { id: props.bestand.id } });
      }

      function resetBereich() {
        updatedBestand.value.lagerbereid = null;
        updatedBestand.value.lagerreiheid = null;
        updatedBestand.value.lagerplatzid = null;
      }

      function updateBestandUmlagern() {
        store.dispatch(BestandActionTypes.UpdateBestandUmlagern, updatedBestand.value).then(() => {
          if (bestandErrorMsg.value == '') {
            closeBestandUmlagernModal();
            store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined);
          }
        });
      }

      const selectedLagerbereich = computed(() => lagerbereiche.value.find((lb: Lagerbereich) => lb.id == updatedBestand.value.lagerbereichid));

      const allLagerbereiche = computed(() => store.getters.lagerbereiche);
      function getLagerbereichInfo(id: number) {
        return allLagerbereiche.value.find((l: Lagerbereich) => l.id == id);
      }

      const lagerorte = computed(() => store.getters.lagerorte);
      const lagerbereiche = computed(() =>
        store.getters.lagerbereiche.filter(
          (l: Lagerbereich) =>
            l.lagerortid == updatedBestand.value.lagerortid && (l.lagerbereichartid == 2 || l.lagerbereichartid == 3 || l.lagerbereichartid == 4)
        )
      );
      const lagerreihen = computed(() => store.getters.lagerreihen.filter((bl: Lagerreihe) => bl.lagerbereichid == updatedBestand.value.lagerbereichid));
      const lagerplaetze = computed(() => store.getters.lagerplaetze.filter((bl: Lagerplatz) => bl.lagerreiheid == updatedBestand.value.lagerreiheid));

      const deleteBestandLoading = computed(() => store.getters.status.deleteBestandLoading);
      function deleteBestand() {
        console.log(props.bestand);
        // var data = new ArtikelBestandVerbrauchRequest(props.bestand.id, props.bestand.anzahl)
        var data = new ArtikelBestandVerbrauchRequest(props.bestand.id, 1);
        console.log(data);
        store.dispatch(BestandActionTypes.UpdateBestandVerbrauch, data);
      }

      return {
        store,
        // einheit,
        getEinheitById,

        lagerort,
        lagerbereich,
        lagerreihe,
        lagerplatz,
        updateBestandLoading,
        bestandErrorMsg,

        lagerReservierung,
        lagerReservierungLagerort,
        lagerReservierungLagerbereich,
        lagerReservierungLagerreihe,
        lagerReservierungLagerplatz,

        bestandUmlagernModalRef,
        closeBestandUmlagernModal,
        showBestandUmlagernModal,

        openBestandDetails,

        resetBereich,

        updatedBestand,
        updateBestandUmlagern,
        selectedLagerbereich,

        getLagerbereichInfo,

        lagerorte,
        lagerbereiche,
        lagerreihen,
        lagerplaetze,

        deleteBestandLoading,
        deleteBestand,
      };
    },
  });
</script>
