<template>
  <div class="d-flex justify-content-center align-items-center mt-5">
    <LoadingSpinner v-if="loading" :text="loadingText" />

    <div v-else class="d-flex-col">
      <div class="mb-5 text-center">
        <!-- <font-awesome-icon icon="users-line" class="me-3 mb-2 fa-3x" /> -->
        <img src="@/assets/icons/zelos.png" alt="Archiv Icon" width="64" />
        <h1>WMS</h1>
      </div>

      <section v-if="displayBenutzerLogin || isdevmode">
        <form class="form-signin p-0" @submit.prevent="login">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder="Anmeldename"
              autocomplete="username"
              v-model="user"
            />
            <label for="floatingInput">Benutzername</label>
          </div>

          <div class="form-floating mb-3">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
              autocomplete="current-password"
              v-model="passwd"
            />
            <label for="floatingPassword">Passwort</label>
          </div>

          <button type="submit" class="w-100 btn btn-lg btn-primary text-light">
            <font-awesome-icon icon="arrow-right-to-bracket" class="me-3" />
            Anmelden
          </button>
        </form>

        <div v-if="azureLoginAvailable">
          <hr class="my-4" />
          <button
            type="button"
            class="btn btn-link text-body-tertiary text-decoration-none w-100"
            @click="switchToAzureLogin()"
          >
            SSO Login
          </button>
        </div>
      </section>

      <section v-if="!displayBenutzerLogin && displayCheckOTPLogin">
        <form
          class="d-flex-col justify-content-center"
          @submit.prevent="checkOTPlogin"
        >
          <p>Geben Sie den Code ein, den Sie per E-Mail bekommen haben.</p>

          <div class="form-floating mb-2 w-75 mx-auto">
            <input
              type="text"
              class="form-control"
              id="floatingOTPInput"
              placeholder="XXXXXX"
              v-model="otpcode"
              required
            />
            <label for="floatingOTPInput">Code eingeben</label>
          </div>

          <div class="form-check form-switch mx-auto mb-4 text-body-tertiary">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="secureBrowserSwitchCheck"
              v-model="secureBrowser"
            />
            <label class="form-check-label" for="secureBrowserSwitchCheck small"
              >Auf diesem Gerät nicht mehr nach dem Code fragen</label
            >
          </div>

          <div v-if="errorMsg == 'Code ungültig.'" class="alert alert-danger">
            {{ errorMsg }}
          </div>

          <div class="w-75 mx-auto">
            <button
              type="submit"
              class="btn btn-lg btn-primary text-light w-100"
            >
              <font-awesome-icon icon="arrow-right-to-bracket" class="me-3" />
              Senden
            </button>
          </div>
        </form>

        <hr class="my-4" />
        <button
          class="btn btn-link text-body-tertiary text-decoration-none w-100"
          @click="switchToBenutzerLoginForm()"
        >
          Zurück zum Login
        </button>
      </section>

      <div v-if="azureLoginAvailable && displayAzureLogin">
        <button class="btn btn-outline-primary btn-lg w-100" @click="signIn">
          <font-awesome-icon :icon="['fab', 'windows']" class="me-3" />
          Weiter mit Mircrosoft
        </button>

        <hr class="my-4" />
        <button
          class="btn btn-link text-body-tertiary text-decoration-none w-100"
          @click="switchToBenutzerLoginForm()"
        >
          Benutzer Login
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  import { computed, defineComponent, ref, watchEffect } from 'vue';
  import { useStore } from '@/store';
  import { LoginRequest, LoginAzureRequest } from '@/models/AuthModels';
  import { ActionTypes as AuthActionTypes } from '@/store/modules/Auth/actions';
  // import { ActionTypes as SessionActionTypes } from '@/store/modules/Session/actions'
  import {
    PublicClientApplication,
    AccountInfo,
    AuthenticationResult,
  } from '@azure/msal-browser';
  import { v4 as uuidv4 } from 'uuid';

  export default defineComponent({
    name: 'Login',

    components: {
      LoadingSpinner,
    },

    setup() {
      const store = useStore();

      const user = ref('');
      const passwd = ref('');
      const otpcode = ref('');
      const secureBrowser = ref(false);

      const loading = computed(() => store.getters.status.authLoading);
      const errorMsg = computed(() => store.getters.status.authErrorMsg);

      // const loginButtonDisabled = computed(() => {
      //   return user.value == "" || passwd.value == ""
      // })

      const msalConfig = computed(() => store.getters.msalConfig);

      const msalInstance = computed(() => {
        let config = msalConfig.value;
        if (config == null || config.auth.redirectUri == null) {
          return null;
        }

        const hostArray = window.location.host.split('.');
        const redirectUriArray = new URL(config.auth.redirectUri).host.split(
          '.'
        );
        if (
          hostArray.length == 3 &&
          redirectUriArray.length == 3 &&
          hostArray[0] == redirectUriArray[0]
        ) {
          config.auth.redirectUri = window.location.href;
        }

        return new PublicClientApplication(config);
      });

      const azureLoginAvailable = computed(() => {
        if (store.getters.msalConfig != null) {
          displayBenutzerLogin.value = false;
          displayAzureLogin.value = true;
          return true;
        } else {
          return false;
        }
        // return store.getters.status.azureLoginAvailable
      });

      const account = ref<AccountInfo>();
      const loginRequest = {
        scopes: ['User.Read'],
      };

      function signIn() {
        // console.log("signIn");
        // console.log(this.msalConfig);
        // console.log(this.msalInstance);
        /**
         * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
         */

        if (msalInstance.value != null) {
          msalInstance.value
            .loginPopup()
            .then((res: any) => {
              handleResponse(res);
            })
            .catch((error: any) => {
              console.error(error);
            });
        }
      }

      function handleResponse(response: AuthenticationResult) {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */

        if (response !== null) {
          let identyToken = response.idToken;
          let username =
            response.account?.username != undefined
              ? response.account?.username
              : '';

          const azureLogin: LoginAzureRequest = new LoginAzureRequest(
            identyToken,
            username
          );
          store.dispatch(AuthActionTypes.LoginAzure, azureLogin);
          // .then(() => {
          //   this.store.dispatch(SessionActionTypes.GetSessions, undefined);
          // })
        }
        // else {
        //   this.selectAccount();
        // }
      }

      const displayBenutzerLogin = ref(true);
      const displayAzureLogin = ref(false);
      const displayCheckOTPLogin = ref(false);

      const isdevmode = computed(() => process.env.NODE_ENV == 'development');
      const mounted = ref(false);
      watchEffect(() => {
        if (azureLoginAvailable.value && mounted.value && !isdevmode.value) {
          signIn();
        }
      });

      return {
        store,
        user,
        passwd,
        otpcode,
        secureBrowser,
        loading,
        errorMsg,
        msalConfig,
        msalInstance,
        azureLoginAvailable,
        account,
        loginRequest,
        displayBenutzerLogin,
        displayAzureLogin,
        displayCheckOTPLogin,
        isdevmode,
        mounted,
        signIn,
      };
    },

    methods: {
      login() {
        this.loadingText = '...Anmeldedaten werden geprüft...';
        var loginRequest: LoginRequest = {
          username: this.user,
          userpassword: this.passwd,
          secureBrowserToken: '',
        };

        if (
          localStorage.getItem('SECBROWSTOKEN') != null &&
          localStorage.getItem('SECBROWSTOKEN') !== undefined
        ) {
          const token = localStorage.SECBROWSTOKEN;
          loginRequest.secureBrowserToken = token;
        }

        this.store.dispatch(AuthActionTypes.Login, loginRequest).then(() => {
          if (this.errorMsg == 'OTP required.') {
            localStorage.removeItem('SECBROWSTOKEN');
            this.displayBenutzerLogin = false;
            this.displayCheckOTPLogin = true;
          }
          // else if (this.errorMsg == "") {
          //   this.store.dispatch(SessionActionTypes.GetSessions, undefined);
          // }
        });
      },

      checkOTPlogin() {
        var loginRequest: LoginRequest = {
          username: this.user,
          userpassword: this.otpcode,
          secureBrowserToken: '',
        };

        if (this.secureBrowser) {
          const token = uuidv4();
          loginRequest.secureBrowserToken = token;
          localStorage.setItem('SECBROWSTOKEN', token);
        }

        this.store
          .dispatch(AuthActionTypes.ValidateOTP, loginRequest)
          .then(() => {
            if (this.errorMsg == 'Code ungültig.') {
              this.displayBenutzerLogin = true;
              this.displayCheckOTPLogin = false;
            }
            // else if (this.errorMsg == "") {
            //   this.store.dispatch(SessionActionTypes.GetSessions, undefined);
            // }
          });
      },

      switchToBenutzerLoginForm() {
        this.displayBenutzerLogin = true;
        this.displayCheckOTPLogin = false;
        this.displayAzureLogin = false;
      },

      switchToAzureLogin() {
        this.displayBenutzerLogin = false;
        this.displayCheckOTPLogin = false;
        this.displayAzureLogin = true;
      },

      // selectAccount() {
      //   /**
      //    * See here for more info on account retrieval:
      //    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
      //    */

      //   const currentAccounts = this.msalInstance.getAllAccounts();
      //   if (currentAccounts.length === 0) {
      //       return;
      //   } else if (currentAccounts.length > 1) {
      //       // Add choose account code here
      //       console.warn("Multiple accounts detected.");
      //   } else if (currentAccounts.length === 1) {
      //       let username = currentAccounts[0].username;
      //       console.log(username);
      //       // showWelcomeMessage(username);
      //   }
      // },
    },

    data() {
      return {
        userData: {
          userName: '',
          userPassword: '',
        },
        loadingText: '',
      };
    },

    mounted() {
      this.mounted = true;

      if (this.msalConfig != null) {
        this.loadingText = '...Verbindung wird hergestellt...';
        this.store.dispatch(AuthActionTypes.SetAzureLoginAvailable, undefined);
        // this.signIn()
      }
    },
  });
</script>
@/models/ArchivModels
