<template>
  <div v-if="artikel == null" class="alert alert-warning">
    Ungültige Artikel ID: {{ id }}
  </div>

  <div v-else-if="artikel.artikelartid == 1 || artikel.artikelartid == 3">
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToArtikel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>

    <div class="d-flex justify-content-between">
      <h1>{{ artikel.titel}}</h1>
      <button type="button" class="btn" @click="openEditArtikel()" title="Artikel bearbeiten"><font-awesome-icon icon="pen" /></button>
    </div>

    <p><strong>Artikel Nr.:</strong> {{ artikel.artikelNr }}</p>

    <p><strong>Artikelart:</strong> {{ artikelart?.name }}</p>

    <!-- <p><strong>Einheit:</strong> {{ artikel.artikel.einheit }}</p> -->
    
    <p>
      <strong>Beschreibung</strong>
    </p>
    <pre>{{ artikel.beschreibung }}</pre>

    <hr class="my-3">

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <h3>Eigenschaften</h3>

      <div class="row w-50 mb-2">
        <div class="col-4">
          <div>
            <span class="fs-5">Eigenschaft</span>
          </div>
        </div>
        
        <div class="col-8">
          <div>
            <span class="fs-5">Wert</span>
          </div>
        </div>
      </div>

      <div v-for="(eigenschaft, index) in artikel.eigenschaften" :key="eigenschaft.key + '-' + index">
        <div class="row w-50 mb-3">
          <div class="col-4">
            <strong class="fst-italic">{{ eigenschaft.key }}</strong>
          </div>
          
          <div class="col-8">
            {{ eigenschaft.value }}
          </div>
        </div>
      </div>
      
      <div v-for="(eigenschaft, index) in artikel.variantenEigenschaften" :key="eigenschaft.key + '-' + index">
        <div class="row w-50 mb-3">
          <div class="col-4">
            <strong class="fst-italic">{{ eigenschaft.key }}</strong>
          </div>
          
          <div class="col-8">
            {{ eigenschaft.value }}
          </div>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <!-- Tags -->
    <div class="my-3">
      <h3>Tags</h3>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in artikel.tags" :key="tag.id" class="lead me-2"><span class="badge text-bg-primary">{{ tags.find(t => t.id == tag.tagid)?.key}}</span></p>
      </div>
    </div>

    <hr class="my-3">

    <!-- EINHEITEN -->
    <div class="mb-3">
      <h3>Einheiten</h3>

      <div class="row w-75 mb-2">
        <div class="col-1"></div>
        <div class="col-2 fs-5">Anzahl</div>
        <div class="col-4">
          <div>
            <span class="fs-5">Einheit</span>
          </div>
        </div>

        <div class="col-5">
          <div>
            <span class="fs-5">Eigenschaften</span>
          </div>
        </div>
      </div>

      <div v-for="(einheit, index) in artikel.einheiten.sort((a, b) => a.pos - b.pos)" :key="einheit.id + '-' + index">
        <div class="row w-75 mb-4">
          <div class="col-1">
            <font-awesome-icon v-if="einheit.default == 1" icon="certificate" />
            <font-awesome-icon v-if="einheit.einzelnEinlagern" icon="barcode" class="ms-2" />
          </div>
          
          <div class="col-2">{{ einheit.anzahl }}</div>

          <div class="col-4">
            <strong class="fst-italic">{{ einheiten.find(e => e.id == einheit.artikelEinheitid)?.name }}</strong> ({{ einheiten.find(e => e.id == einheit.artikelEinheitid)?.kurz }})
          </div>

          <div class="col-5 pt-2">
            <div v-for="eigenschaft in einheit.eigenschaften" :key="eigenschaft.id" class="row">
              <div class="col-4"><strong class="fst-italic">{{ eigenschaft.key }}</strong></div>
              <div class="col-8">{{ eigenschaft.value }}</div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <hr class="my-3">
    
    <!-- VERBUNDARTIKEL -->
    <div v-if="artikel.artikelartid == 3" class="mb-3">
      <h3>Verbundartikel</h3>

      <div class="row mx-1 mb-2">
        <div class="col-2 fw-bold">Artikel Nr.</div>
        <div class="col-4 fw-bold">Artikel</div>
        <div class="col-2 fw-bold">Anzahl</div>
        <div class="col-2 fw-bold">Einheit</div>
        <div class="col-2"></div>
      </div>

      <div v-for="(art, index) in artikel.verbundArtikel" :key="art.id">
        <DisplayVerbundArtikelRow :verbundartikel="art" :index="index" />
      </div>

    </div>
  </div>

      <!-- VARIANTENEIGENSCHAFTEN -->
  <div class="mb-3" v-else-if="artikel.artikelartid == 2">

    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToArtikel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <h1>{{ artikel.titel}}</h1>

    <hr class="my-3">

    <h3>Varianten</h3>

    <div class="row mx-1 mb-2">
      <div class="col-2 fw-bold">Artikel Nr.</div>
      <div class="col-2 fw-bold">Artikel</div>
      <div class="col-6 fw-bold">Eigenschaften</div>
      <div class="col-2"></div>
    </div>
    <div v-for="(variante, index) in varianten" :key="variante.id + '-' + index">
      <DisplayArtikelvarianteRow :artikel="variante" :index="index" />
    </div>

  </div>

  <!-- <code>
    {{ JSON.stringify(artikel, null, 2) }}
  </code> -->
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { useRouter } from "vue-router"

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DisplayArtikelvarianteRow from "@/components/Artikel/DisplayArtikelvarianteRow.vue";
import DisplayVerbundArtikelRow from "@/components/Artikel/DisplayVerbundArtikelRow.vue";
import { Artikel, ArtikelArtikelEinheit, ArtikelEinheit } from "@/models/ArtikelModels";

export default defineComponent({
  name: "DetailsArtikelView",

  components: {
    LoadingSpinner,
    DisplayArtikelvarianteRow,
    DisplayVerbundArtikelRow
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter();

    const artikel = computed(() => store.getters.artikel.find(a => a.id === parseInt(props.id)));
    const artikelart = computed(() => store.getters.artikelarten.find(a => a.id === artikel.value?.artikelartid))
    const tags = computed(() => store.getters.tags)

    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getSubEinheitInfo(id: number | null): ArtikelEinheit | undefined {
      const subeinheit = artikel.value?.einheiten.find((e: ArtikelArtikelEinheit) => e.id == id)
      return einheiten.value.find((e: ArtikelEinheit) => e.id == subeinheit?.artikelEinheitid)
    }

    const varianten = computed(() => artikel.value?.artikelartid == 2 ? store.getters.artikel.filter(a => a.ownerId == artikel.value?.id) : new Array<Artikel>())

    return {
      store,
      router,
      artikel,
      artikelart,
      tags,
      einheiten,
      getSubEinheitInfo,
      varianten
    }
  },

  methods: {
    returnToArtikel () {
      if (this.artikel != null && (this.artikel.ownerId > 0)) {
        this.router.push({name: "ArtikelDetails", params: {id: this.artikel.ownerId}})
      }
      else {
        this.router.push({name: "Artikel"})
      }
    },

    openEditArtikel () {
      this.router.push({name: "EditArtikel", params: {id: this.id}})
    }
  }

})
</script>

<style scoped>
.footerbar {
  position: absolute;
  bottom: 0;
}

code {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>