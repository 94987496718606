<template>
  <h1 class="m-0 p-0">Artikel importieren</h1>

  <div v-if="errorMsg != ''" class="alert alert-danger my-4">Es ist ein Fehler aufgetreten: {{ errorMsg }}</div>

  <hr class="my-4" />

  <!-- CSV Datei auswählen -->
  <section v-if="csvData.length == 0">
    <div class="d-flex justify-content-center text-center">
      <div class="w-50">
        <label for="artikel-import-input" class="form-label lead fw-bold">CSV-Datei mit Artikelstammdaten auswählen </label>
        <input class="form-control" type="file" id="artikel-import-input" accept=".csv" @change="handleFileUpload" />
      </div>
    </div>

    <hr class="my-4" />

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary" @click="cancel()">Abbrechen</button>
    </div>
  </section>

  <!-- Mapping erstellen -->
  <section
    v-if="csvData.length > 0 && newArtikels.length == 0"
    @drop.prevent="handleDrop($event, 'csvHeaders')"
    @dragenter.prevent="dragEnter()"
    @dragover.prevent="dragOver()"
    @dragleave.prevent="dragLeave()"
  >
    <!-- SELECT TEMPLATE -->
    <div class="row mb-3">
      <div class="col-4">
        <label for="artikel-template" class="form-label lead fw-bold">Artikel Template auswählen</label>
        <select class="form-select" id="artikel-template" v-model="selectedArtikeltemplate" @change="onTemplateChange()">
          <option :value="null">Kein Template...</option>
          <option v-for="template in artikeltemplates" :key="template.id" :value="template">
            {{ template.titel }}
          </option>
        </select>
      </div>

      <div class="col my-auto text-end">
        <ShowImportDataModal :header="csvHeaders" :data="csvData" />
      </div>
    </div>

    <hr class="my-3" />

    <div v-if="selectedArtikeltemplate">
      <div class="d-flex flex-wrap my-4">
        <div
          v-for="(param, index) in csvHeaders.sort((a, b) => a.localeCompare(b))"
          :key="index"
          :id="'header-' + param"
          class="p-2 border border-dark me-2 mb-2"
          draggable="true"
          v-on:dragstart="startDrag($event, param, 'csvHeaders')"
        >
          {{ param }}
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <!-- Artikel TITEL -->
          <div class="row mb-3">
            <div class="col-4 my-auto"><strong>Titel:</strong> {{ selectedArtikeltemplate.artikeltitel }}</div>
            <div
              class="col bg-white border p-3 d-flex flex-wrap"
              @drop="handleDrop($event, 'mapTitel')"
              @dragenter.prevent="dragEnter()"
              @dragover.prevent="dragOver()"
              @dragleave.prevent="dragLeave()"
            >
              <div
                v-for="(param, index) in mapTitel"
                :key="index"
                class="p-2 border border-dark me-2 mb-2"
                draggable="true"
                v-on:dragstart="startDrag($event, param, 'mapTitel')"
              >
                {{ param }}
              </div>
            </div>
          </div>

          <!-- Artikel ARTIKELNUMMER -->
          <div class="row mb-3">
            <div class="col-4 my-auto">
              <strong>Artikel Nr.:</strong>
              {{ selectedArtikeltemplate.artikelnummer }}
            </div>

            <div
              class="col bg-white border p-3 d-flex flex-wrap"
              @drop="handleDrop($event, 'mapArtikelnr')"
              @dragenter.prevent="dragEnter()"
              @dragover.prevent="dragOver()"
              @dragleave.prevent="dragLeave()"
            >
              <div
                v-for="(param, index) in mapArtikelnr"
                :key="index"
                class="p-2 border border-dark me-2 mb-2"
                draggable="true"
                v-on:dragstart="startDrag($event, param, 'mapArtikelnr')"
              >
                {{ param }}
              </div>
            </div>
          </div>

          <!-- Artikel BESCHREIBUNG -->
          <div class="row mb-3">
            <div class="col-2 my-auto">
              <strong>Beschreibung:</strong>
            </div>

            <div class="col-2 my-auto">
              {{ selectedArtikeltemplate.artikelbeschreibung }}
            </div>

            <div
              class="col bg-white border p-3 d-flex flex-wrap"
              @drop="handleDrop($event, 'mapBeschreibung')"
              @dragenter.prevent="dragEnter()"
              @dragover.prevent="dragOver()"
              @dragleave.prevent="dragLeave()"
            >
              <div
                v-for="(param, index) in mapBeschreibung"
                :key="index"
                class="p-2 border border-dark me-2 mb-2"
                draggable="true"
                v-on:dragstart="startDrag($event, param, 'mapBeschreibung')"
              >
                {{ param }}
              </div>
            </div>
          </div>

          <hr class="my-3" />

          <!-- EIGENSCHAFTEN -->
          <div class="mb-3">
            <p class="fw-bold">Eigenschaften</p>

            <div v-for="(eigenschaft, index) in selectedArtikeltemplate.eigenschaften" :key="index" class="row mb-2">
              <div class="col-1 my-auto">
                <strong>{{ eigenschaft.key }}:</strong>
              </div>

              <div class="col-3 my-auto">({{ eigenschaft.value }})</div>

              <div
                class="col bg-white border p-3 d-flex flex-wrap"
                @drop="handleDrop($event, 'mapEigenschaften', eigenschaft.key)"
                @dragenter.prevent="dragEnter()"
                @dragover.prevent="dragOver()"
                @dragleave.prevent="dragLeave()"
              >
                <div
                  v-for="(param, idx) in mapEigenschaften[eigenschaft.key]"
                  :key="idx"
                  class="p-2 border border-dark me-2 mb-2"
                  draggable="true"
                  v-on:dragstart="startDrag($event, param, 'mapEigenschaften', eigenschaft.key)"
                >
                  {{ param }}
                </div>
              </div>
            </div>
          </div>

          <hr class="my-3" />

          <!-- EINHEITEN -->
          <div class="mb-3">
            <p class="fw-bold">Einheiten</p>

            <div v-for="(einheit, index) in selectedArtikeltemplate.einheiten.sort((a, b) => a.pos - b.pos)" :key="index" class="row mb-2">
              <div class="col-1 my-auto">
                <strong>{{ getEinheitNameById(einheit.artikelEinheitid) }}:</strong>
              </div>

              <div class="col-3 my-auto">(Anzahl: {{ einheit.anzahl }})</div>

              <div
                class="col bg-white border p-3 d-flex flex-wrap"
                @drop="handleDrop($event, 'mapEinheiten', einheit.artikelEinheitid.toString())"
                @dragenter.prevent="dragEnter()"
                @dragover.prevent="dragOver()"
                @dragleave.prevent="dragLeave()"
              >
                <div
                  v-for="(param, idx) in mapEinheiten[einheit.artikelEinheitid]"
                  :key="idx"
                  class="p-2 border border-dark me-2 mb-2"
                  draggable="true"
                  v-on:dragstart="startDrag($event, param, 'mapEinheiten', einheit.artikelEinheitid.toString())"
                >
                  {{ param }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-4" />

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" @click="generateArtikel()">Artikel (Vorschau) generieren</button>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary" @click="cancel()">Abbrechen</button>
    </div>
  </section>

  <!-- Artikel Vorschau, bearbeiten und speichern -->
  <section v-if="newArtikels.length > 0">
    <h2>Vorschaue</h2>

    <div class="d-flex">
      <div class="row mx-1 flex-fill">
        <div class="col-2 fw-bold">Artikel Nr.</div>
        <div class="col fw-bold">Artikel</div>
        <div class="col-3 fw-bold">Aktionen</div>
      </div>
      <button type="button" class="btn ms-2" disabled><font-awesome-icon icon="trash" /></button>
    </div>

    <div class="d-flex" v-for="(artikel, index) in newArtikels" :key="index">
      <ArtikelImportArtikelRow :importArtikel="artikel" class="flex-fill" />
      <button type="button" class="btn ms-2" @click="removeArtikel(index)"><font-awesome-icon icon="trash" /></button>
    </div>

    <hr class="my-4" />

    <div v-if="errorMsg != ''" class="alert alert-danger">Es ist ein Fehler aufgetreten: {{ errorMsg }}</div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary" @click="cancel()">Abbrechen</button>
      <button type="button" class="btn btn-primary ms-3" @click="saveData()" :disabled="hasError">Speichern</button>
    </div>
  </section>

  <LoadingOverlay v-if="loading" />
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import { Artikeltemplate } from '@/models/ArtikeltemplateModels';
  import {
    Artikel,
    ArtikelTag,
    ArtikelEigenschaft,
    ArtikelEinheit,
    ArtikelEinheitEigenschaft,
    ArtikelArtikelEinheit,
    ImportArtikel,
  } from '@/models/ArtikelModels';

  import ArtikelImportArtikelRow from '@/components/Artikel/ArtikelImportArtikelRow.vue';
  import ShowImportDataModal from '@/components/Artikel/ShowImportDataModal.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  //#region READ CSV
  const fileInput = ref<File | null>(null);
  const csvData = ref<Array<{ [key: string]: string }>>([]);
  const csvHeaders = ref<string[]>([]);

  const handleFileUpload = (event: Event) => {
    // Reset data
    csvData.value = [];
    csvHeaders.value = [];
    selectedArtikeltemplate.value = null;
    newArtikels.value = [];

    // Read file
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      fileInput.value = target.files[0];
      parseCSVFile(fileInput.value);
    }
  };

  const parseCSVFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const lines = text.split('\n');
      const headers = lines[0].split(';');

      csvData.value = lines.slice(1).map((line) => {
        const values = line.split(';');
        const artikel: { [key: string]: string } = {};
        headers.forEach((header, index) => {
          artikel[header.trim()] = values[index] ? values[index].trim() : '';
        });
        return artikel;
      });

      csvHeaders.value = headers;
    };

    reader.readAsText(file);
  };
  //#endregion

  //#region ARTIKELTEMPLATES und DATEN
  const artikeleinheiten = computed(() => store.getters.artikeleinheiten);
  const artikelartikeleinheiten = computed(() => {
    var result = new Array<ArtikelArtikelEinheit>();
    for (var einheit of artikeleinheiten.value) {
      var value = new ArtikelArtikelEinheit();
      value.id = einheit.id;
      value.artikelEinheit = einheit;
      value.artikelEinheitid = einheit.id;
      result.push(value);
    }

    return result;
  });

  function getEinheitNameById(id: number): string {
    let response = 'unbekannt';

    const einheit = artikeleinheiten.value.find((a) => a.id == id);
    if (einheit) {
      response = einheit.name;
    }

    return response;
  }

  const artikeltemplates = computed(() => store.getters.artikeltemplates);
  const selectedArtikeltemplate = ref<Artikeltemplate | null>(null);

  function onTemplateChange() {
    csvHeaders.value.push(...mapArtikelnr.value);
    mapArtikelnr.value = [];

    csvHeaders.value.push(...mapTitel.value);
    mapTitel.value = [];

    csvHeaders.value.push(...mapBeschreibung.value);
    mapBeschreibung.value = [];

    for (const [, values] of Object.entries(mapEigenschaften.value)) {
      csvHeaders.value.push(...values);
    }
    mapEigenschaften.value = {};

    for (const [, values] of Object.entries(mapEinheiten.value)) {
      csvHeaders.value.push(...values);
    }
    mapEinheiten.value = {};

    newArtikels.value = [];
  }
  //#endregion

  //#region MAPPING
  const mapArtikelnr = ref<string[]>([]);
  const mapTitel = ref<string[]>([]);
  const mapBeschreibung = ref<string[]>([]);
  const mapEigenschaften = ref<{ [key: string]: string[] }>({});
  const mapEinheiten = ref<{ [key: string]: string[] }>({});
  //#endregion

  //#region HANDLE DRAG&DROP
  function startDrag(ev: any, item: string, area: string, areaIndex: string = '0') {
    ev.dataTransfer.setData('text', item + ',' + area + ',' + areaIndex);
  }

  function handleDrop(ev: any, area: string, areaIndex: string = '0') {
    ev.stopPropagation();

    const data = ev.dataTransfer.getData('text');
    const [item, dragArea, index] = data.split(',');

    if (dragArea == area && index == areaIndex) {
      return;
    }

    // add
    if (area == 'mapTitel') {
      mapTitel.value.push(item);
    } else if (area == 'mapArtikelnr') {
      mapArtikelnr.value.push(item);
    } else if (area == 'mapBeschreibung') {
      mapBeschreibung.value.push(item);
    } else if (area == 'mapEigenschaften') {
      if (mapEigenschaften.value[areaIndex] == undefined) {
        mapEigenschaften.value[areaIndex] = [];
      }

      mapEigenschaften.value[areaIndex].push(item);
    } else if (area == 'mapEinheiten') {
      if (mapEinheiten.value[areaIndex] == undefined) {
        mapEinheiten.value[areaIndex] = [];
      }

      mapEinheiten.value[areaIndex].push(item);
    } else if (area == 'csvHeaders') {
      csvHeaders.value.push(item);
    }

    // remove
    if (dragArea == 'mapTitel') {
      mapTitel.value = mapTitel.value.filter((header) => header != item);
    } else if (dragArea == 'mapArtikelnr') {
      mapArtikelnr.value = mapArtikelnr.value.filter((header) => header != item);
    } else if (dragArea == 'mapBeschreibung') {
      mapBeschreibung.value = mapBeschreibung.value.filter((header) => header != item);
    } else if (dragArea == 'mapEigenschaften') {
      if (mapEigenschaften.value[index]) {
        mapEigenschaften.value[index] = mapEigenschaften.value[index].filter((header) => header != item);
      }
    } else if (dragArea == 'mapEinheiten') {
      if (mapEinheiten.value[index]) {
        mapEinheiten.value[index] = mapEinheiten.value[index].filter((header) => header != item);
      }
    } else if (dragArea == 'csvHeaders') {
      csvHeaders.value = csvHeaders.value.filter((header) => header != item);
    }
  }

  function dragEnter() {}

  function dragOver() {}

  function dragLeave() {}
  //#endregion

  //#region ARTIKEL GENERIEREN
  const generateArtikelLoading = ref(false);
  const newArtikels = ref<Array<ImportArtikel>>([]);

  function createArtikelWithArtikeltemplate(): Artikel {
    const newArtikel = new Artikel();

    const template = JSON.parse(JSON.stringify(selectedArtikeltemplate.value)) as Artikeltemplate;
    newArtikel.titel = template.artikeltitel;
    newArtikel.artikelartid = template.artikelartid;
    newArtikel.artikelNr = template.artikelnummer;
    newArtikel.beschreibung = template.artikelbeschreibung;

    for (var tag of template.tags) {
      var newTag = new ArtikelTag();
      newTag.artikelid = newArtikel.id;
      newTag.tagid = tag.tagid;
      newArtikel.tags.push(newTag);
    }

    // Eigenschaften
    newArtikel.eigenschaften = JSON.parse(JSON.stringify(template.eigenschaften)) as Array<ArtikelEigenschaft>;
    for (var eigen1 of newArtikel.eigenschaften) {
      eigen1.id = 0;
    }

    // Einheiten
    newArtikel.einheiten = new Array<ArtikelArtikelEinheit>();
    for (var e of template.einheiten) {
      var einheit = new ArtikelArtikelEinheit();
      einheit.id = 0;
      einheit.default = e.default;
      einheit.artikelEinheit = e.artikelEinheit;
      einheit.artikelid = newArtikel.id;
      einheit.anzahl = e.anzahl;
      einheit.pos = e.pos;
      einheit.einzelnEinlagern = e.einzelnEinlagern;

      einheit.artikelEinheitid = e.artikelEinheitid;
      var artikeleinheit = artikeleinheiten.value.find((a) => a.id == e.artikelEinheitid);
      einheit.artikelEinheit = artikeleinheit != null ? artikeleinheit : new ArtikelEinheit();

      einheit.eigenschaften = new Array<ArtikelEinheitEigenschaft>();
      if (e.eigenschaften.length > 0) {
        for (var eigenschaft of e.eigenschaften) {
          var neweigenschaft = new ArtikelEinheitEigenschaft();
          neweigenschaft.id = 0;
          neweigenschaft.artikelArtikelEinheitid = einheit.id;
          neweigenschaft.key = eigenschaft.key;
          neweigenschaft.value = eigenschaft.value;
          einheit.eigenschaften.push(neweigenschaft);
        }
      }

      newArtikel.einheiten.push(einheit);

      var artikelartikeleinheitIndex = artikelartikeleinheiten.value.findIndex((a) => a.artikelEinheitid == einheit.artikelEinheitid);
      if (artikelartikeleinheitIndex != -1) {
        artikelartikeleinheiten.value.splice(artikelartikeleinheitIndex, 1, einheit);
      }
    }

    return newArtikel;
  }

  function mapValues(artikel: Artikel, index: number): Artikel {
    if (csvData.value.length == 0) {
      return artikel;
    }

    // Titel
    if (mapTitel.value.length > 0) {
      mapTitel.value.forEach((titel) => {
        const value = csvData.value[index][titel];
        artikel.titel += ' ' + value;
      });

      artikel.titel = artikel.titel.trim();
    }

    // Artikelnummer
    if (mapArtikelnr.value.length > 0) {
      mapArtikelnr.value.forEach((nr) => {
        const value = csvData.value[index][nr];
        artikel.artikelNr += value;
      });

      artikel.artikelNr = artikel.artikelNr.trim();
    }

    // Beschreibung
    if (mapBeschreibung.value.length > 0) {
      mapBeschreibung.value.forEach((e) => {
        const value = csvData.value[index][e];
        artikel.beschreibung += ' ' + value;
      });

      artikel.beschreibung = artikel.beschreibung.trim();
    }

    // Eigenschaften
    for (const [key, values] of Object.entries(mapEigenschaften.value)) {
      values.forEach((value) => {
        const csvValue = csvData.value[index][value];

        const eigenschaft = artikel.eigenschaften.find((e) => e.key === key);

        if (eigenschaft) {
          eigenschaft.value = csvValue.trim();
        }
      });
    }

    // Einheiten
    for (const [key, values] of Object.entries(mapEinheiten.value)) {
      values.forEach((value) => {
        const csvValue = csvData.value[index][value];

        const einheit = artikel.einheiten.find((e) => e.artikelEinheitid.toString() === key);

        if (einheit) {
          const anzahl = parseInt(csvValue);
          einheit.anzahl = anzahl ? anzahl : 0;
        }
      });
    }

    return artikel;
  }

  function generateArtikel() {
    generateArtikelLoading.value = true;
    newArtikels.value = [];

    if (csvData.value.length == 0) {
      return;
    }

    for (var i = 0; i < csvData.value.length; i++) {
      let newArtikel = createArtikelWithArtikeltemplate();
      newArtikel = mapValues(newArtikel, i);

      const data = new ImportArtikel(newArtikel);

      if (store.getters.artikel.find((a) => a.artikelNr == newArtikel.artikelNr)) {
        data.existing = true;
        data.task = 1;
      }

      newArtikels.value.push(data);
    }

    generateArtikelLoading.value = false;
  }

  function removeArtikel(index: number) {
    newArtikels.value.splice(index, 1);
  }
  //#endregion

  //#region SAVE OR CANCEL

  const hasError = computed(() => {
    return newArtikels.value.some((a) => a.hasError);
  });

  const loading = computed(() => store.getters.status.createArtikelLoading || store.getters.status.updateArtikelLoading || generateArtikelLoading.value);
  const errorMsg = computed(() => store.getters.status.artikelErrorMsg);

  async function saveData() {
    const createData = new Array<Artikel>();
    const updateData = new Array<Artikel>();

    for (var artikel of newArtikels.value) {
      if (artikel.existing) {
        // Ignore
        if (artikel.task == 0) {
          continue;
        }

        // Update
        else if (artikel.task == 1) {
          const existingArtikel = store.getters.artikel.find((a) => a.artikelNr == artikel.artikel.artikelNr);
          if (existingArtikel) {
            const updateArtikel = JSON.parse(JSON.stringify(existingArtikel)) as Artikel;

            updateArtikel.artikelNr = artikel.artikel.artikelNr;
            updateArtikel.titel = artikel.artikel.titel;
            updateArtikel.artikelartid = artikel.artikel.artikelartid;
            updateArtikel.beschreibung = artikel.artikel.beschreibung;
            updateArtikel.einheiten = artikel.artikel.einheiten;
            updateArtikel.einheitEigenschaften = artikel.artikel.einheitEigenschaften;
            updateArtikel.eigenschaften = artikel.artikel.eigenschaften;
            updateArtikel.variantenEigenschaften = artikel.artikel.variantenEigenschaften;
            updateArtikel.verbundArtikel = artikel.artikel.verbundArtikel;
            updateArtikel.tags = artikel.artikel.tags;
            updateArtikel.isVariation = artikel.artikel.isVariation;
            updateArtikel.ownerId = artikel.artikel.ownerId;
            updateArtikel.isActive = artikel.artikel.isActive;
            updateArtikel.isDeleted = artikel.artikel.isDeleted;

            updateData.push(updateArtikel);
          } else {
            createData.push(artikel.artikel);
          }
        }

        // Copy
        else if (artikel.task == 2) {
          let artikelNrExtension = 1;
          while (store.getters.artikel.find((a) => a.artikelNr == artikel.artikel.artikelNr + '(' + artikelNrExtension + ')')) {
            artikelNrExtension++;
          }

          artikel.artikel.artikelNr += '(' + artikelNrExtension + ')';
          artikel.artikel.titel += ' (' + artikelNrExtension + ')';

          createData.push(artikel.artikel);
        }
      } else {
        createData.push(artikel.artikel);
      }
    }

    store.dispatch(ArtikelActionTypes.CreateArtikelRange, createData).then(() => {
      if (errorMsg.value == '') {
        store.dispatch(ArtikelActionTypes.UpdateArtikelRange, updateData).then(() => {
          if (errorMsg.value == '') {
            router.push({ name: 'Artikel' });
          } else {
            window.scrollTo(0, document.body.scrollHeight);
          }
        });
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    });
  }

  function cancel() {
    router.push({ name: 'Artikel' });
  }
  //#endregion
</script>
