export class ArtikelBestand {
  id: number;
  lieferungid: number | null;
  lieferungposid: number | null;

  auftragid: number | null;
  auftragposid: number | null;
  auslieferungsdatum: Date | null;

  artikelid: number;
  artikelnummer: string;
  artikeltitel: string;
  artikeldaten: Array<ArtikelBestandEigenschaft>;
  // anzahl: number
  // einheitid: number^
  einheiten: Array<ArtikelbestandArtikelEinheit>;
  tags: Array<BestandTag>;
  barcode: string;
  lieferdatum: Date;
  geliefert: number;
  inTransport: boolean;
  lagerortid: number | null;
  lagerbereichid: number | null;
  lagerreiheid: number | null;
  lagerplatzid: number | null;

  constructor() {
    this.id = 0;
    this.lieferungid = null;
    this.lieferungposid = null;

    this.auftragid = null;
    this.auftragposid = null;
    this.auslieferungsdatum = null;

    this.artikelid = 0;
    this.artikelnummer = '';
    this.artikeltitel = '';
    this.artikeldaten = new Array<ArtikelBestandEigenschaft>();
    // this.anzahl = 1;
    // this.einheitid = 0;
    this.einheiten = new Array<ArtikelbestandArtikelEinheit>();
    this.tags = new Array<BestandTag>();
    this.barcode = '';
    this.lieferdatum = new Date();
    this.geliefert = 0;
    this.inTransport = false;
    this.lagerortid = null;
    this.lagerbereichid = null;
    this.lagerreiheid = null;
    this.lagerplatzid = null;
  }
}

export class BestandTag {
  id: number;
  artikelbestandid: number;
  tagid: number;

  constructor() {
    this.id = 0;
    this.artikelbestandid = 0;
    this.tagid = 0;
  }
}

export class ArtikelBestandVerbrauchRequest {
  id: number;
  anzahl: number;

  constructor(id: number, anzahl: number) {
    this.id = id;
    this.anzahl = anzahl;
  }
}

export class ArtikelbestandArtikelEinheit {
  id: number;
  artikelbestandid: number;
  artikelEinheitid: number;
  basisAnzahl: number;
  anzahl: number;
  pos: number;

  constructor() {
    this.id = 0;
    this.artikelbestandid = 0;
    this.artikelEinheitid = 0;
    this.basisAnzahl = 1;
    this.anzahl = 0;
    this.pos = 1;
  }
}

export class ArtikelBestandEigenschaft {
  id: number;
  artikelbestandid: number;
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.id = 0;
    this.artikelbestandid = 0;
    this.key = key;
    this.value = value;
  }
}

export class ArtikelBestandBewegung {
  id: number;
  beschreibung: string;
  artikelbestandid: number;
  anzahl: number;
  einheitid: number;
  lagerortid: number;
  lagerbereichid: number;
  lagerreiheid: number;
  lagerplatzid: number;
  adate: Date;
  benutzerId: number;

  constructor() {
    this.id = 0;
    this.beschreibung = '';
    this.artikelbestandid = 0;
    this.anzahl = 0;
    this.einheitid = 0;
    this.lagerortid = 0;
    this.lagerbereichid = 0;
    this.lagerreiheid = 0;
    this.lagerplatzid = 0;
    this.adate = new Date();
    this.benutzerId = 0;
  }
}

export class ArtikelBestandAuslagernRequest {
  artikelbestandid: number;
  anzahl: number;
  einheitid: number;

  constructor(artikelbestandid: number, anzahl: number, einheitid: number) {
    this.artikelbestandid = artikelbestandid;
    this.anzahl = anzahl;
    this.einheitid = einheitid;
  }
}
