<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer Auftrag</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createAuftrag()" autocomplete="off">
    <h3>Kunde</h3>
    <div class="row mb-3">
      <div class="col">
        <label for="kunde-nr" class="form-label">Kunden Nr.</label>
        <AutocompleteInputSelect :id="'kunde-nr'" ref="kundeIdInput" :select-list="kundenNrList" :inputValue="kundeInput.id" @set-value="setKundeById" />
      </div>

      <div class="col">
        <label for="kunde-firma" class="form-label">Firma</label>
        <AutocompleteInputSelect
          :id="'kunde-firma'"
          ref="kundeFirmaInput"
          :select-list="kundenFirmaList"
          :inputValue="kundeInput.firma"
          @set-value="setKundeByFirma"
        />
      </div>

      <div class="col">
        <label for="kunde-name" class="form-label">Name</label>
        <AutocompleteInputSelect
          :id="'kunde-name'"
          ref="kundeNameInput"
          :select-list="kundenNameList"
          :inputValue="kundeInput.name"
          @set-value="setKundeByName"
        />
      </div>
    </div>

    <hr class="my-4" />

    <h3>Auslieferung</h3>
    <div class="row mb-3">
      <div class="col-3">
        <label for="date-input" class="form-label">Auslieferungsdatum</label>
        <input type="date" class="form-control" id="date-input" v-model="auslieferdatumDateConverter" />
      </div>

      <div class="col-3">
        <label for="lagerort-input" class="form-label">Lagerort</label>
        <AutocompleteInputSelect
          id="lagerort-input"
          ref="lagerortInput"
          :select-list="lagerorteNameList"
          :inputValue="selectedLagerortName"
          @set-value="setLagerortByName"
        />
      </div>

      <div class="col-3" v-if="neuerAuftrag.auftrag.lagerortid != null && neuerAuftrag.auftrag.lagerortid != 0">
        <label for="lagerbereich-input" class="form-label">Lagerbereich</label>
        <AutocompleteInputSelect
          id="lagerbereich-input"
          ref="lagerbereichInput"
          :select-list="lagerbereicheNameList"
          :inputValue="selectedLagerbereichName"
          @set-value="setLagerbereichByName"
        />
      </div>
    </div>

    <hr class="my-4" />

    <div class="mb-3">
      <label for="Notizen" class="form-label">Notizen</label>
      <textarea class="form-control" rows="3" v-model="neuerAuftrag.auftrag.notizen"></textarea>
    </div>

    <h3>Positionen</h3>

    <div v-if="auftragPositionen.positionen.length > 0" class="row mb-2">
      <div class="col fw-bold">ArtikelNr</div>
      <div class="col fw-bold">Artikel</div>
      <div class="col fw-bold">Anzahl</div>
      <div class="col fw-bold">Einheit</div>
    </div>

    <EditAuftragPosRow v-for="(pos, index) in auftragPositionen.positionen" :key="index" :pos="pos" :index="index" />

    <button type="button" class="btn btn-secondary" @click="addPosition()"><font-awesome-icon icon="plus" /> Position hinzufügen</button>

    <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
  //#region Imports
  import { computed, reactive, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { Auftrag, AuftragPos, EditAuftragPos } from '@/models/AuftragModels';
  import { Kunde } from '@/models/KundeModels';
  import { Lagerort, Lagerbereich } from '@/models/LagerModels';
  import { Artikel } from '@/models/ArtikelModels';

  import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
  import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
  import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
  import EditAuftragPosRow from '@/components/Auftrag/EditAuftragPosRow.vue';
  //#endregion

  const store = useStore();
  const router = useRouter();

  const loading = computed(() => store.getters.status.createAuftragLoading);
  const errorMsg = computed(() => store.getters.status.auftragErrorMsg);

  const neuerAuftrag = reactive({
    auftrag: new Auftrag(),
  });

  //#region Auslieferungsdatum
  const auslieferdatumDateConverter = computed({
    get() {
      if (neuerAuftrag.auftrag.auslieferungsdatum) {
        const datum = neuerAuftrag.auftrag.auslieferungsdatum;
        return datum.getFullYear() + '-' + ('0' + (datum.getMonth() + 1)).slice(-2) + '-' + ('0' + datum.getDate()).slice(-2);
      } else {
        return '';
      }
    },

    set(newValue) {
      if (newValue == '') {
        newValue = new Date().toISOString();
      }
      neuerAuftrag.auftrag.auslieferungsdatum = new Date(newValue);
    },
  });
  //#endregion

  //#region Lager
  const lagerorte = computed(() => store.getters.lagerorte);
  const lagerorteNameList = computed(() => lagerorte.value.map((obj: Lagerort) => obj['name']));
  const selectedLagerortName = ref('');

  const lagerbereiche = computed(() => store.getters.lagerbereiche.filter((l: Lagerbereich) => l.lagerortid == neuerAuftrag.auftrag.lagerortid));
  const lagerbereicheNameList = computed(() => lagerbereiche.value.map((obj: Lagerbereich) => obj['name']));
  const selectedLagerbereichName = ref('');

  function setLagerortByName(name: string) {
    const lagerort = lagerorte.value.find((l: Lagerort) => l.name == name);
    if (lagerort != null) {
      neuerAuftrag.auftrag.lagerortid = lagerort.id;
    }
  }

  function setLagerbereichByName(name: string) {
    const lagerbereich = lagerbereiche.value.find((l: Lagerbereich) => l.name == name);
    if (lagerbereich != null) {
      neuerAuftrag.auftrag.lagerbereichid = lagerbereich.id;
    }
  }
  //#endregion

  function createAuftrag() {
    neuerAuftrag.auftrag.positionen = new Array<AuftragPos>();

    for (var pos of auftragPositionen.positionen) {
      var artikel = store.getters.artikel.find((a: Artikel) => a.titel == pos.artikeltitel);

      if (artikel) {
        var newpos = new AuftragPos();
        newpos.artikelid = artikel.id;
        newpos.anzahl = pos.anzahl;
        newpos.einheitid = pos.einheitid;

        neuerAuftrag.auftrag.positionen.push(newpos);
      }
    }

    store.dispatch(AuftragActionTypes.CreateAuftrag, neuerAuftrag.auftrag).then(() => {
      if (errorMsg.value == '') {
        returnToMain();
      }
    });
  }

  function returnToMain() {
    clearInputs();
    router.push({ name: 'Auftraege' });
  }

  function clearInputs() {
    neuerAuftrag.auftrag = new Auftrag();
    auftragPositionen.positionen = new Array<EditAuftragPos>();
  }

  //#region Kunde
  const kunden = computed(() => store.getters.kunden);
  const kundenNrList = computed(() => kunden.value.map((obj: Kunde) => obj['id'].toString()));
  const kundenFirmaList = computed(() => kunden.value.map((obj: Kunde) => obj['firma']));
  const kundenNameList = computed(() => kunden.value.map((obj: Kunde) => obj['vorname'] + ' ' + obj['name']));

  const kundeInput = reactive({
    id: '',
    firma: '',
    name: '',
  });

  const kundeIdInput = ref();
  const kundeFirmaInput = ref();
  const kundeNameInput = ref();

  function setKundeById(id: string) {
    const kunde = kunden.value.find((k: Kunde) => k.id == parseInt(id));

    if (kunde != null) {
      setKunde(kunde);
      kundeFirmaInput.value.inputvalue = kunde.firma;
      kundeNameInput.value.inputvalue = `${kunde.vorname} ${kunde.name}`;
    }
  }

  function setKundeByFirma(firma: string) {
    const kunde = kunden.value.find((k: Kunde) => k.firma == firma);

    if (kunde != null) {
      setKunde(kunde);
      kundeIdInput.value.inputvalue = kunde.id.toString();
      kundeNameInput.value.inputvalue = `${kunde.vorname} ${kunde.name}`;
    }
  }

  function setKundeByName(name: string) {
    const kunde = kunden.value.find((k: Kunde) => `${k.vorname} ${k.name}` == name);

    if (kunde != null) {
      setKunde(kunde);
      kundeIdInput.value.inputvalue = kunde.id.toString();
      kundeFirmaInput.value.inputvalue = kunde.firma;
    }
  }

  function setKunde(kunde: Kunde) {
    neuerAuftrag.auftrag.kundeid = kunde.id;
  }
  //#endregion

  //#region Positionen
  const auftragPositionen = reactive({
    positionen: new Array<EditAuftragPos>(),
  });

  function addPosition() {
    auftragPositionen.positionen.push(new EditAuftragPos(auftragPositionen.positionen.length));
  }
  //#endregion

  function loadData() {
    store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined);
    store.dispatch(KundeActionTypes.GetKunde, undefined);
    store.dispatch(ArtikelActionTypes.GetArtikel, undefined);
  }

  onMounted(() => {
    loadData();
  });
</script>
